export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const LOGIN_CLEAR_STATE = "LOGIN_CLEAR_STATE";
export const SIGN_UP_REQUEST = "SIGN_UP_REQUEST";
export const SIGN_UP_SUCCESS = "SIGN_UP_SUCCESS";
export const SIGN_UP_FAILURE = "SIGN_UP_FAILURE";
export const FORGOT_PASSWORD_REQUEST = "FORGOT_PASSWORD_REQUEST";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAILURE = "FORGOT_PASSWORD_FAILURE";
export const FORGOT_PASSWORD_CLEAR_STATE = "FORGOT_PASSWORD_CLEAR_STATE";
export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE";
export const EMAIL_VERIFICATION_REQUEST = "EMAIL_VERIFICATION_REQUEST";
export const EMAIL_VERIFICATION_SUCCESS = "EMAIL_VERIFICATION_SUCCESS";
export const EMAIL_VERIFICATION_FAILURE = "EMAIL_VERIFICATION_FAILURE";
export const EMAIL_RESEND_REQUEST = "EMAIL_RESEND_REQUEST";
export const EMAIL_RESEND_SUCCESS = "EMAIL_RESEND_SUCCESS";
export const EMAIL_RESEND_FAILURE = "EMAIL_RESEND_FAILURE";
export const TRACKING_SCRIPT_REQUEST = "TRACKING_SCRIPT_REQUEST";
export const TRACKING_SCRIPT_SUCCESS = "TRACKING_SCRIPT_SUCCESS";
export const TRACKING_SCRIPT_FAILURE = "TRACKING_SCRIPT_FAILURE";
export const WEBSITE_DETAILS_REQUEST = "WEBSITE_DETAILS_REQUEST";
export const WEBSITE_DETAILS_SUCCESS = "WEBSITE_DETAILS_SUCCESS";
export const WEBSITE_DETAILS_FAILURE = "WEBSITE_DETAILS_FAILURE";
export const SHOPIFY_INTEGRATE_REQUEST = "SHOPIFY_INTEGRATE_REQUEST";
export const SHOPIFY_INTEGRATE_SUCCESS = "SHOPIFY_INTEGRATE_SUCCESS";
export const SHOPIFY_INTEGRATE_FAILURE = "SHOPIFY_INTEGRATE_FAILURE";
export const SHOPIFY_ACCESS_REQUEST = "SHOPIFY_ACCESS_REQUEST";
export const SHOPIFY_ACCESS_SUCCESS = "SHOPIFY_ACCESS_SUCCESS";
export const SHOPIFY_ACCESS_FAILURE = "SHOPIFY_ACCESS_FAILURE";
export const BIGCOM_INTEGRATE_REQUEST = "BIGCOM_INTEGRATE_REQUEST";
export const BIGCOM_INTEGRATE_SUCCESS = "BIGCOM_INTEGRATE_SUCCESS";
export const BIGCOM_INTEGRATE_FAILURE = "BIGCOM_INTEGRATE_FAILURE";
export const BIGCOM_ACCESS_REQUEST = "BIGCOM_ACCESS_REQUEST";
export const BIGCOM_ACCESS_SUCCESS = "BIGCOM_ACCESS_SUCCESS";
export const BIGCOM_ACCESS_FAILURE = "BIGCOM_ACCESS_FAILURE";
export const LOGOUT = "LOGOUT";
export const REFRESH_TOKEN_REQUEST = "REFRESH_TOKEN_REQUEST";
export const REFRESH_TOKEN_SUCCESS = "REFRESH_TOKEN_SUCCESS";
export const REFRESH_TOKEN_FAILURE = "REFRESH_TOKEN_FAILURE";
export const PASSWORD_CHANGE_REQUEST = "PASSWORD_CHANGE_REQUEST";
export const PASSWORD_CHANGE_SUCCESS = "PASSWORD_CHANGE_SUCCESS";
export const PASSWORD_CLEAR_STATE = "PASSWORD_CLEAR_STATE";
export const PASSWORD_CHANGE_FAILURE = "PASSWORD_CHANGE_FAILURE";
export const REQUEST_FREE_DEMO_REQUEST = "REQUEST_FREE_DEMO_REQUEST";
export const REQUEST_FREE_DEMO_SUCCESS = "REQUEST_FREE_DEMO_SUCCESS";
export const REQUEST_FREE_DEMO_FAILURE = "REQUEST_FREE_DEMO_FAILURE";
export const USER_DETAILS_REQUEST = "USER_DETAILS_REQUEST";
export const USER_DETAILS_SUCCESS = "USER_DETAILS_SUCCESS";
export const USER_DETAILS_FAILURE = "USER_DETAILS_FAILURE";
export const OVERVIEW_DATA_REQUESTED = "OVERVIEW_DATA_REQUESTED";
export const OVERVIEW_DATA_RECEIVED = "OVERVIEW_DATA_RECEIVED";
export const OVERVIEW_DATA_FAILED = "OVERVIEW_DATA_FAILED";
export const OVERVIEW_COMPARE = "OVERVIEW_COMPARE";
export const PUSH_PARAM_CHANGE = "PUSH_PARAM_CHANGE";
export const PUSH_PRIORITY_PARAM_CHANGE = "PUSH_PRIORITY_PARAM_CHANGE";
export const MONTH_COMPARE = "MONTH_COMPARE";
export const PRODUCT_BUNDLE_DATA_REQUEST = "PRODUCT_BUNDLE_DATA_REQUEST";
export const PRODUCT_BUNDLE_DATA_SUCCESS = "PRODUCT_BUNDLE_DATA_SUCCESS";
export const PRODUCT_BUNDLE_DATA_FAILURE = "PRODUCT_BUNDLE_DATA_FAILURE";
export const SEGMENT_ORDER_DATA_REQUESTED = "SEGMENT_ORDER_DATA_REQUESTED";
export const SEGMENT_ORDER_DATA_RECEIVED = "SEGMENT_ORDER_DATA_RECEIVED";
export const SEGMENT_ORDER_DATA_FAILED = "SEGMENT_ORDER_DATA_FAILED";
export const SEGMENT_PATH_DATA_REQUESTED = "SEGMENT_PATH_DATA_REQUESTED";
export const SEGMENT_PATH_DATA_RECEIVED = "SEGMENT_PATH_DATA_RECEIVED";
export const SEGMENT_PATH_DATA_FAILED = "SEGMENT_PATH_DATA_FAILED";
export const SEGMENT_PRODUCT_DATA_REQUESTED = "SEGMENT_PRODUCT_DATA_REQUESTED";
export const SEGMENT_PRODUCT_DATA_RECEIVED = "SEGMENT_PRODUCT_DATA_RECEIVED";
export const SEGMENT_PRODUCT_DATA_FAILED = "SEGMENT_PRODUCT_DATA_FAILED";
export const SEGMENT_COMPARE_ORDER_DATA_REQUESTED =
  "SEGMENT_COMPARE_ORDER_DATA_REQUESTED";
export const SEGMENT_COMPARE_ORDER_DATA_RECEIVED =
  "SEGMENT_COMPARE_ORDER_DATA_RECEIVED";
export const SEGMENT_COMPARE_ORDER_DATA_FAILED =
  "SEGMENT_COMPARE_ORDER_DATA_FAILED";
export const SEGMENT_COMPARE_PATH_DATA_REQUESTED =
  "SEGMENT_COMPARE_PATH_DATA_REQUESTED";
export const SEGMENT_COMPARE_PATH_DATA_RECEIVED =
  "SEGMENT_COMPARE_PATH_DATA_RECEIVED";
export const SEGMENT_COMPARE_PATH_DATA_FAILED =
  "SEGMENT_COMPARE_PATH_DATA_FAILED";
export const SEGMENT_COMPARE_PRODUCT_DATA_REQUESTED =
  "SEGMENT_COMPARE_PRODUCT_DATA_REQUESTED";
export const SEGMENT_COMPARE_PRODUCT_DATA_RECEIVED =
  "SEGMENT_COMPARE_PRODUCT_DATA_RECEIVED";
export const SEGMENT_COMPARE_PRODUCT_DATA_FAILED =
  "SEGMENT_COMPARE_PRODUCT_DATA_FAILED";
export const POPULATION_DATA_REQUESTED = "POPULATION_DATA_REQUESTED";
export const POPULATION_DATA_RECEIVED = "POPULATION_DATA_RECEIVED";
export const POPULATION_DATA_FAILED = "POPULATION_DATA_FAILED";
export const SET_A_GROUP_DATES = "SET_A_GROUP_DATES";
export const SET_B_GROUP_DATES = "SET_B_GROUP_DATES";
export const SET_C_GROUP_DATES = "SET_C_GROUP_DATES";
// export const SET_D_GROUP_DATES = "SET_D_GROUP_DATES";
export const SET_GOOGLE_ANALYTICS_DATES = "SET_GOOGLE_ANALYTICS_DATES";
export const SET_REDEAL_ANALYTICS_DATES = "SET_REDEAL_ANALYTICS_DATES";
export const SET_FACEBOOK_ANALYTICS_DATES = "SET_FACEBOOK_ANALYTICS_DATES";
export const SET_ALL_GROUP_DATES = "SET_ALL_GROUP_DATES";
export const SET_ALL_FILTER = "SET_ALL_FILTER";
export const SET_A_FILTER = "SET_A_FILTER";
export const SET_B_FILTER = "SET_B_FILTER";
export const SET_C_FILTER = "SET_C_FILTER";
export const SET_CURRENCY_FIELDS = "SET_CURRENCY_FIELDS";
export const SET_SEGMENT_PAGE_TAB = "SET_SEGMENT_PAGE_TAB";
export const SET_REPORT_PAGE_TAB = "SET_REPORT_PAGE_TAB";
export const SET_CUSTOMER_ANALYTICS_SEGMENT_NAME =
  "SET_CUSTOMER_ANALYTICS_SEGMENT_NAME";
export const USER_SEGMENT_DATA_REQUEST = "USER_SEGMENT_DATA_REQUEST";
export const USER_SEGMENT_DATA_SUCCESS = "USER_SEGMENT_DATA_SUCCESS";
export const USER_SEGMENT_DATA_FAILURE = "USER_SEGMENT_DATA_FAILURE";
export const USER_SEGMENT_DATA_RESET = "USER_SEGMENT_DATA_RESET"
export const NOTIFICATION_DATA_REQUEST = "NOTIFICATION_DATA_REQUEST";
export const NOTIFICATION_DATA_SUCCESS = "NOTIFICATION_DATA_SUCCESS";
export const NOTIFICATION_DATA_FAILURE = "NOTIFICATION_DATA_FAILURE";
export const NOTIFICATION_DELETE_REQUEST = "NOTIFICATION_DELETE_REQUEST";
export const NOTIFICATION_DELETE_SUCCESS = "NOTIFICATION_DELETE_SUCCESS";
export const NOTIFICATION_DELETE_FAILURE = "NOTIFICATION_DELETE_FAILURE";
export const AUTH_URL_REQUEST = "AUTH_URL_REQUEST";
export const AUTH_URL_RECEIVED = "AUTH_URL_RECEIVED";
export const AUTH_URL_FAILED = "AUTH_URL_FAILED";
export const FB_AUTH_URL_REQUEST = "FB_AUTH_URL_REQUEST";
export const FB_AUTH_URL_RECEIVED = "FB_AUTH_URL_RECEIVED";
export const FB_AUTH_URL_FAILED = "FB_AUTH_URL_FAILED";
export const GA_AUTH_VAR_SENT_REQUEST = "GA_AUTH_VAR_SENT_REQUEST";
export const GA_AUTH_VAR_SENT_SUCCESS = "GA_AUTH_VAR_SENT_SUCCESS";
export const GA_AUTH_VAR_SENT_FAILED = "GA_AUTH_VAR_SENT_FAILED";
export const FB_AUTH_VAR_SENT_REQUEST = "FB_AUTH_VAR_SENT_REQUEST";
export const FB_AUTH_VAR_SENT_SUCCESS = "FB_AUTH_VAR_SENT_SUCCESS";
export const FB_AUTH_VAR_SENT_FAILED = "FB_AUTH_VAR_SENT_FAILED";
export const GAID_SENT_REQUEST = "GAID_SENT_REQUEST";
export const GAID_SENT_SUCCESS = "GAID_SENT_SUCCESS";
export const GAID_SENT_FAILED = "GAID_SENT_FAILED";
export const FBID_SENT_REQUEST = "FBID_SENT_REQUEST";
export const FBID_SENT_SUCCESS = "FBID_SENT_SUCCESS";
export const FBID_SENT_FAILED = "FBID_SENT_FAILED";
export const REDEALID_SENT_REQUEST = "REDEALID_SENT_REQUEST";
export const REDEALID_SENT_SUCCESS = "REDEALID_SENT_SUCCESS";
export const REDEALID_SENT_FAILED = "REDEALID_SENT_FAILED";
export const PUSH_AUDIENCE_REQUEST = "PUSH_AUDIENCE_REQUEST";
export const PUSH_AUDIENCE_SUCCESS = "PUSH_AUDIENCE_SUCCESS";
export const PUSH_AUDIENCE_FAILED = "PUSH_AUDIENCE_FAILED";
export const PUSH_AUDIENCE_RESET = "PUSH_AUDIENCE_RESET"
export const FB_PUSH_AUDIENCE_REQUEST = "FB_PUSH_AUDIENCE_REQUEST";
export const FB_PUSH_AUDIENCE_SUCCESS = "FB_PUSH_AUDIENCE_SUCCESS";
export const FB_PUSH_AUDIENCE_FAILED = "FB_PUSH_AUDIENCE_FAILED";
export const LIST_AUDIENCE_REQUEST = "LIST_AUDIENCE_REQUEST";
export const LIST_AUDIENCE_SUCCESS = "LIST_AUDIENCE_SUCCESS";
export const LIST_AUDIENCE_FAILED = "LIST_AUDIENCE_FAILED";
export const LIST_AUDIENCE_RESET = "LIST_AUDIENCE_RESET"
export const DELETE_CONNECTION_REQUEST = "DELETE_CONNECTION_REQUEST";
export const DELETE_CONNECTION_SUCCESS = "DELETE_CONNECTION_SUCCESS";
export const DELETE_CONNECTION_FAILED = "DELETE_CONNECTION_FAILED";
export const REDEAL_ANALYTICS_REQUEST = "REDEAL_ANALYTICS_REQUEST";
export const REDEAL_ANALYTICS_SUCCESS = "REDEAL_ANALYTICS_SUCCESS";
export const REDEAL_ANALYTICS_FAILED = "REDEAL_ANALYTICS_FAILED";
export const GOOGLEAD_ANALYTICS_REQUEST = "GOOGLEAD_ANALYTICS_REQUEST";
export const GOOGLEAD_ANALYTICS_SUCCESS = "GOOGLEAD_ANALYTICS_SUCCESS";
export const GOOGLEAD_ANALYTICS_FAILED = "GOOGLEAD_ANALYTICS_FAILED";
export const REDEAL_COMPARE_ANALYTICS_REQUEST =
  "REDEAL_COMPARE_ANALYTICS_REQUEST";
export const REDEAL_COMPARE_ANALYTICS_SUCCESS =
  "REDEAL_COMPARE_ANALYTICS_SUCCESS";
export const REDEAL_COMPARE_ANALYTICS_FAILED =
  "REDEAL_COMPARE_ANALYTICS_FAILED";
export const GOOGLEAD_COMPARE_ANALYTICS_REQUEST =
  "GOOGLEAD_COMPARE_ANALYTICS_REQUEST";
export const GOOGLEAD_COMPARE_ANALYTICS_SUCCESS =
  "GOOGLEAD_COMPARE_ANALYTICS_SUCCESS";
export const GOOGLEAD_COMPARE_ANALYTICS_FAILED =
  "GOOGLEAD_COMPARE_ANALYTICS_FAILED";
export const GOOGLEAD_ACTIVE_LIST_REQUEST = "GOOGLEAD_ACTIVE_LIST_REQUEST";
export const GOOGLEAD_ACTIVE_LIST_SUCCESS = "GOOGLEAD_ACTIVE_LIST_SUCCESS";
export const GOOGLEAD_ACTIVE_LIST_FAILED = "GOOGLEAD_ACTIVE_LIST_FAILED";
export const SET_CUSTOMER_URL = "SET_CUSTOMER_URL";
export const SET_CUSTOMER_EMAIL_DOWNLOAD = "SET_CUSTOMER_EMAIL_DOWNLOAD";
export const SET_REPORT_BUTTON = "SET_REPORT_BUTTON";
export const REPORT_DOWNLOAD_ENTRY_REQUEST = "REPORT_DOWNLOAD_ENTRY_REQUEST";
export const REPORT_DOWNLOAD_ENTRY_SUCCESS = "REPORT_DOWNLOAD_ENTRY_SUCCESS";
export const REPORT_DOWNLOAD_ENTRY_FAILURE = "REPORT_DOWNLOAD_ENTRY_FAILURE";
export const TERMS_ACCEPTED_REQUEST = "TERMS_ACCEPTED_REQUEST";
export const TERMS_ACCEPTED_SUCCESS = "TERMS_ACCEPTED_SUCCESS";
export const TERMS_ACCEPTED_FAILED = "TERMS_ACCEPTED_FAILED";
export const SINGLE_BUYER_REPEAT_REQUEST = "SINGLE_BUYER_REPEAT_REQUEST";
export const SINGLE_BUYER_REPEAT_SUCCESS = "SINGLE_BUYER_REPEAT_SUCCESS";
export const SINGLE_BUYER_REPEAT_FAILED = "SINGLE_BUYER_REPEAT_FAILED";
export const REPEAT_NEXT_3M_REQUEST = "REPEAT_NEXT_3M_REQUEST";
export const REPEAT_NEXT_3M_SUCCESS = "REPEAT_NEXT_3M_SUCCESS";
export const REPEAT_NEXT_3M_FAILED = "REPEAT_NEXT_3M_FAILED";
export const VISITOR_TO_CUSTOMER_REQUEST = "VISITOR_TO_CUSTOMER_REQUEST";
export const VISITOR_TO_CUSTOMER_SUCCESS = "VISITOR_TO_CUSTOMER_SUCCESS";
export const VISITOR_TO_CUSTOMER_FAILED = "VISITOR_TO_CUSTOMER_FAILED";
export const PREDICTIONS_SUMMARY_REQUEST = "PREDICTIONS_SUMMARY_REQUEST";
export const PREDICTIONS_SUMMARY_SUCCESS = "PREDICTIONS_SUMMARY_SUCCESS";
export const PREDICTIONS_SUMMARY_FAILED = "PREDICTIONS_SUMMARY_FAILED";
export const AUDIENCE_HISTORY_REQUEST = "AUDIENCE_HISTORY_REQUEST";
export const AUDIENCE_HISTORY_SUCCESS = "AUDIENCE_HISTORY_SUCCESS";
export const AUDIENCE_HISTORY_FAILED = "AUDIENCE_HISTORY_FAILED";
export const UPDATE_REPLACE_REQUEST = "UPDATE_REPLACE_REQUEST";
export const UPDATE_REPLACE_SUCCESS = "UPDATE_REPLACE_SUCCESS";
export const UPDATE_REPLACE_FAILED = "UPDATE_REPLACE_FAILED";
export const FB_UPDATE_REPLACE_REQUEST = "FB_UPDATE_REPLACE_REQUEST";
export const FB_UPDATE_REPLACE_SUCCESS = "FB_UPDATE_REPLACE_SUCCESS";
export const FB_UPDATE_REPLACE_FAILED = "FB_UPDATE_REPLACE_FAILED";
export const EDIT_AUDIENCE_REQUEST = "EDIT_AUDIENCE_REQUEST";
export const EDIT_AUDIENCE_SUCCESS = "EDIT_AUDIENCE_SUCCESS";
export const EDIT_AUDIENCE_FAILED = "EDIT_AUDIENCE_FAILED";
export const ANALYTICS_MONTH_COMPARE = "ANALYTICS_MONTH_COMPARE";
export const SET_RETRY_RE_MARKET = "SET_RETRY_RE_MARKET";
export const LOGIN_POPUP_CHANGE = "LOGIN_POPUP_CHANGE";
export const BUSINESS_PROFILE_REQUEST = "BUSINESS_PROFILE_REQUEST";
export const BUSINESS_PROFILE_SUCCESS = "BUSINESS_PROFILE_SUCCESS";
export const BUSINESS_PROFILE_FAILED = "BUSINESS_PROFILE_FAILED";
export const HELPCENTER_QUERY_REQUEST = "HELPCENTER_QUERY_REQUEST";
export const HELPCENTER_QUERY_SUCCESS = "HELPCENTER_QUERY_SUCCESS";
export const HELPCENTER_QUERY_FAILED = "HELPCENTER_QUERY_FAILED";
export const CONTACTUS_FORM_REQUEST = "CONTACTUS_FORM_REQUEST";
export const CONTACTUS_FORM_SUCCESS = "CONTACTUS_FORM_SUCCESS";
export const CONTACTUS_FORM_FAILED = "CONTACTUS_FORM_FAILED";
export const CONTACTUS_FORM_CLEAR_STATE = "CONTACTUS_FORM_CLEAR_STATE";
export const DATA_STATUS_ORDER_REQUEST = "DATA_STATUS_ORDER_REQUEST";
export const DATA_STATUS_ORDER_SUCCESS = "DATA_STATUS_ORDER_SUCCESS";
export const DATA_STATUS_ORDER_FAILURE = "DATA_STATUS_ORDER_FAILURE";
export const DATA_STATUS_ORDER_ITEM_REQUEST = "DATA_STATUS_ORDER_ITEM_REQUEST";
export const DATA_STATUS_ORDER_ITEM_SUCCESS = "DATA_STATUS_ORDER_ITEM_SUCCESS";
export const DATA_STATUS_ORDER_ITEM_FAILURE = "DATA_STATUS_ORDER_ITEM_FAILURE";
export const DATA_STATUS_TRACKINGINFO_REQUEST =
  "DATA_STATUS_TRACKINGINFO_REQUEST";
export const DATA_STATUS_TRACKINGINFO_SUCCESS =
  "DATA_STATUS_TRACKINGINFO_SUCCESS";
export const DATA_STATUS_TRACKINGINFO_FAILURE =
  "DATA_STATUS_TRACKINGINFO_FAILURE";
export const PRODUCT_TOUR_RUN = "PRODUCT_TOUR_RUN";
export const PRODUCT_TOUR_RUN_END = "PRODUCT_TOUR_RUN_END";
export const PRODUCT_TOUR_RUN_START = "PRODUCT_TOUR_RUN_START";
export const PRODUCT_TOUR_STEP_INDEX = "PRODUCT_TOUR_STEP_INDEX";
export const PRODUCT_TOUR_ACTION_REQUEST = "PRODUCT_TOUR_ACTION_REQUEST";
export const PRODUCT_TOUR_ACTION_SUCCESS = "PRODUCT_TOUR_ACTION_SUCCESS";
export const PRODUCT_TOUR_ACTION_FAILED = "PRODUCT_TOUR_ACTION_FAILED";
export const CUSTOMER_TREND_REQUEST = "CUSTOMER_TREND_REQUEST";
export const CUSTOMER_TREND_SUCCESS = "CUSTOMER_TREND_SUCCESS";
export const CUSTOMER_TREND_FAILURE = "CUSTOMER_TREND_FAILURE";
export const CUSTOMER_TREND_SELECTED_METRIC_VALUE =
  "CUSTOMER_TREND_SELECTED_METRIC_VALUE";
export const CUSTOMER_TREND_GRAPH_DATA_OBJ = "CUSTOMER_TREND_GRAPH_DATA_OBJ";
export const CHURN_PREDICTION_REQUEST = "CHURN_PREDICTION_REQUEST";
export const CHURN_PREDICTION_SUCCESS = "CHURN_PREDICTION_SUCCESS";
export const CHURN_PREDICTION_FAILURE = "CHURN_PREDICTION_FAILURE";
export const DOWNTIME_BANNER_REQUEST = "DOWNTIME_BANNER_REQUEST";
export const DOWNTIME_BANNER_SUCCESS = "DOWNTIME_BANNER_SUCCESS";
export const DOWNTIME_BANNER_FAILURE = "DOWNTIME_BANNER_FAILURE";
export const SET_SHOW_PASSWORD_POPUP = "SET_SHOWED_PASSWORD_POPUP";
export const CUSTOMER_REPORT_CUSTOM_REQUEST = "CUSTOMER_REPORT_CUSTOM_REQUEST";
export const CUSTOMER_REPORT_CUSTOM_SUCCESS = "CUSTOMER_REPORT_CUSTOM_SUCCESS";
export const CUSTOMER_REPORT_CUSTOM_FAILED = "CUSTOMER_REPORT_CUSTOM_FAILED";
export const GENERATE_CONTENT_REQUEST = "GENERATE_CONTENT_REQUEST";
export const GENERATE_CONTENT_SUCCESS = "GENERATE_CONTENT_SUCCESS";
export const GENERATE_CONTENT_FAILURE = "GENERATE_CONTENT_FAILURE";
export const GENERATE_MORE_CONTENT_REQUEST = "GENERATE_MORE_CONTENT_REQUEST";
export const GENERATE_MORE_CONTENT_SUCCESS = "GENERATE_MORE_CONTENT_SUCCESS";
export const GENERATE_MORE_CONTENT_FAILURE = "GENERATE_MORE_CONTENT_FAILURE";
export const GOOGLE_REAUTH_URL_REQUEST = "GOOGLE_REAUTH_URL_REQUEST";
export const GOOGLE_REAUTH_URL_RECEIVED = "GOOGLE_REAUTH_URL_RECEIVED";
export const GOOGLE_REAUTH_URL_FAILED = "GOOGLE_REAUTH_URL_FAILED";
export const SHOPIFY_DATA_STATUS_ORDER_REQUEST =
  "SHOPIFY_DATA_STATUS_ORDER_REQUEST";
export const SHOPIFY_DATA_STATUS_ORDER_SUCCESS =
  "SHOPIFY_DATA_STATUS_ORDER_SUCCESS";
export const SHOPIFY_DATA_STATUS_ORDER_FAILURE =
  "SHOPIFY_DATA_STATUS_ORDER_FAILURE";
export const SHOPIFY_DATA_STATUS_ORDER_ITEM_REQUEST =
  "SHOPIFY_DATA_STATUS_ORDER_ITEM_REQUEST";
export const SHOPIFY_DATA_STATUS_ORDER_ITEM_SUCCESS =
  "SHOPIFY_DATA_STATUS_ORDER_ITEM_SUCCESS";
export const SHOPIFY_DATA_STATUS_ORDER_ITEM_FAILURE =
  "SHOPIFY_DATA_STATUS_ORDER_ITEM_FAILURE";
export const SHOPIFY_DATA_STATUS_TRACKINGINFO_REQUEST =
  "SHOPIFY_DATA_STATUS_TRACKINGINFO_REQUEST";
export const SHOPIFY_DATA_STATUS_TRACKINGINFO_SUCCESS =
  "SHOPIFY_DATA_STATUS_TRACKINGINFO_SUCCESS";
export const SHOPIFY_DATA_STATUS_TRACKINGINFO_FAILURE =
  "SHOPIFY_DATA_STATUS_TRACKINGINFO_FAILURE";
export const SHOPIFY_DATA_PROGRESS_REQUEST = "SHOPIFY_DATA_PROGRESS_REQUEST";
export const SHOPIFY_DATA_PROGRESS_SUCCESS = "SHOPIFY_DATA_PROGRESS_SUCCESS";
export const SHOPIFY_DATA_PROGRESS_FAILURE = "SHOPIFY_DATA_PROGRESS_FAILURE";
export const FETCH_AND_CLUSTER_REQUEST = "FETCH_AND_CLUSTER_REQUEST";
export const FETCH_AND_CLUSTER_SUCCESS = "FETCH_AND_CLUSTER_SUCCESS";
export const FETCH_AND_CLUSTER_FAILED = "FETCH_AND_CLUSTER_FAILED";
export const SET_SHOPIFY_DATA_PROGRESS = "SET_SHOPIFY_DATA_PROGRESS";
export const SHOPIFY_APP_HMAC_REQUEST = "SHOPIFY_APP_HMAC_REQUEST";
export const SHOPIFY_APP_HMAC_SUCCESS = "SHOPIFY_APP_HMAC_SUCCESS";
export const SHOPIFY_APP_HMAC_FAILURE = "SHOPIFY_APP_HMAC_FAILURE";
export const SHOPIFY_BUID_ACCESS_TOKEN_REQUEST =
  "SHOPIFY_BUID_ACCESS_TOKEN_REQUEST";
export const SHOPIFY_BUID_ACCESS_TOKEN_SUCCESS =
  "SHOPIFY_BUID_ACCESS_TOKEN_SUCCESS";
export const SHOPIFY_BUID_ACCESS_TOKEN_FAILURE =
  "SHOPIFY_BUID_ACCESS_TOKEN_FAILURE";
export const SAVE_CUSTOM_FILTER_REQUEST = "SAVE_CUSTOM_FILTER_REQUEST";
export const SAVE_CUSTOM_FILTER_SUCCESS = "SAVE_CUSTOM_FILTER_SUCCESS";
export const SAVE_CUSTOM_FILTER_FAILED = "SAVE_CUSTOM_FILTER_FAILED";
export const CLEAR_CUSTOM_FILTER = "CLEAR_CUSTOM_FILTER";
export const CLEAR_DELETE_FILTER = "CLEAR_DELETE_FILTER";
export const CLEAR_AUDIENCES_FILTER = "CLEAR_AUDIENCES_FILTER";
export const FETCH_CUSTOM_FILTER_REQUEST = "FETCH_CUSTOM_FILTER_REQUEST";
export const FETCH_CUSTOM_FILTER_SUCCESS = "FETCH_CUSTOM_FILTER_SUCCESS";
export const FETCH_CUSTOM_FILTER_FAILED = "FETCH_CUSTOM_FILTER_FAILED";
export const FETCH_CUSTOM_FILTER_RESET = "FETCH_CUSTOM_FILTER_RESET";

export const SYNCHED_AUDIENCE_HISTORY_CLICKED =
  "SYNCHED_AUDIENCE_HISTORY_CLICKED";
export const SELECTED_SYNCHED_AUDIENCE_DATA = "SELECTED_SYNCHED_AUDIENCE_DATA";

export const SYNCED_AUDIENCE_EDIT_REQUEST = "SYNCED_AUDIENCE_EDIT_REQUEST";
export const SYNCED_AUDIENCE_EDIT_SUCCESS = "SYNCED_AUDIENCE_EDIT_SUCCESS";
export const SYNCED_AUDIENCE_EDIT_FAILED = "SYNCED_AUDIENCE_EDIT_FAILED";
export const SYNCED_AUDIENCE_EDIT_RESET = "SYNCED_AUDIENCE_EDIT_RESET";

export const SYNCED_AUDIENCE_ENABLE_DISABLE_REQUEST =
  "SYNCED_AUDIENCE_ENABLE_DISABLE_REQUEST";
export const SYNCED_AUDIENCE_ENABLE_DISABLE_SUCCESS =
  "SYNCED_AUDIENCE_ENABLE_DISABLE_SUCCESS";
export const SYNCED_AUDIENCE_ENABLE_DISABLE_FAILED =
  "SYNCED_AUDIENCE_ENABLE_DISABLE_FAILED";
export const SYNCED_AUDIENCE_ENABLE_DISABLE_RESET =
  "SYNCED_AUDIENCE_ENABLE_DISABLE_RESET";

export const SUCCESS_MODAL_VALUE = "SUCCESS_MODAL_VALUE";
export const FAILURE_MODAL_VALUE = "FAILURE_MODAL_VALUE";

export const GET_CUSTOM_FILTER_AUDIENCE_REQUEST =
  "GET_CUSTOM_FILTER_AUDIENCE_REQUEST";
export const GET_CUSTOM_FILTER_AUDIENCE_SUCCESS =
  "GET_CUSTOM_FILTER_AUDIENCE_SUCCESS";
export const GET_CUSTOM_FILTER_AUDIENCE_FAILED =
  "GET_CUSTOM_FILTER_AUDIENCE_FAILED";
export const GET_CUSTOM_FILTER_AUDIENCE_RESET =
  "GET_CUSTOM_FILTER_AUDIENCE_RESET";

export const RENAME_FILTER_REQUEST = "RENAME_FILTER_REQUEST";
export const RENAME_FILTER_SUCCESS = "RENAME_FILTER_SUCCESS";
export const RENAME_FILTER_FAILED = "RENAME_FILTER_FAILED";
export const SET_WINDOW_HEIGHT = "SET_WINDOW_HEIGHT";
export const PRODUCT_DATA_REQUEST = "PRODUCT_DATA_REQUEST";
export const PRODUCT_DATA_SUCCESS = "PRODUCT_DATA_SUCCESS";
export const PRODUCT_DATA_FAILED = "PRODUCT_DATA_FAILED";
export const PRODUCT_CATEGORY_DATA_REQUEST = "PRODUCT_CATEGORY_DATA_REQUEST";
export const PRODUCT_CATEGORY_DATA_SUCCESS = "PRODUCT_CATEGORY_DATA_SUCCESS";
export const PRODUCT_CATEGORY_DATA_FAILED = "PRODUCT_CATEGORY_DATA_FAILED";
export const SET_USER_FILTER = "SET_USER_FILTER";
export const SET_RESET_USER_FILTER = "SET_RESET_USER_FILTER";
export const SET_RESET_VALUES_TO_ALL = "SET_RESET_VALUES_TO_ALL";
export const SET_RESET_VALUES_TO_FILTER = "SET_RESET_VALUES_TO_FILTER";
export const SET_REMARKET_PAGE_INITIAL_VALUES =
  "SET_REMARKET_PAGE_INITIAL_VALUES";
export const SET_FILTER_VALUES = "SET_FILTER_VALUES";
export const SET_PAGE_TAB = "SET_PAGE_TAB";
export const SET_APPLIED_FILTER_VALUES = "SET_APPLIED_FILTER_VALUES";
export const SET_REMARKET_LIST_NAME = "SET_REMARKET_LIST_NAME";
export const CREATE_NEW_FITLER_OBJECT = "CREATE_NEW_FITLER_OBJECT";
export const SET_REPORT_BUTTON_APPLIED = "SET_REPORT_BUTTON_APPLIED";
export const ADD_CUSTOM_FILTER_OBJECT = "ADD_CUSTOM_FILTER_OBJECT";
export const SIGN_UP_CLEAR_STATE = "SIGN_UP_CLEAR_STATE";
export const PRODUCT_PAGE_META_DATA_REQUESTED =
  "PRODUCT_PAGE_META_DATA_REQUESTED";
export const PRODUCT_PAGE_META_DATA_RECEIVED =
  "PRODUCT_PAGE_META_DATA_RECEIVED";
export const PRODUCT_PAGE_META_DATA_FAILED = "PRODUCT_PAGE_META_DATA_FAILED";
export const DELETE_CUSTOMER_FILTER_REQUEST = "DELETE_CUSTOMER_FILTER_REQUEST";
export const DELETE_CUSTOMER_FILTER_SUCCESS = "DELETE_CUSTOMER_FILTER_SUCCESS";
export const DELETE_CUSTOMER_FILTER_FAILED = "DELETE_CUSTOMER_FILTER_FAILED";
export const AUDIENCES_CUSTOMER_FILTER_REQUEST =
  "AUDIENCES_CUSTOMER_FILTER_REQUEST";
export const AUDIENCES_CUSTOMER_FILTER_SUCCESS =
  "AUDIENCES_CUSTOMER_FILTER_SUCCESS";
export const AUDIENCES_CUSTOMER_FILTER_FAILED =
  "AUDIENCES_CUSTOMER_FILTER_FAILED";
export const MFA_ENABLE_SUCCESS = "MFA_ENABLE_SUCCESS";
export const MFA_ENABLE_FAILURE = "MFA_ENABLE_FAILURE";
export const MFA_ENABLE_REQUEST = "MFA_ENABLE_REQUEST";
export const MFA_VERIFICATION_SUCCESS = "MFA_VERIFICATION_SUCCESS";
export const MFA_VERIFICATION_REQUEST = "MFA_VERIFICATION_REQUEST";
export const MFA_VERIFICATION_FAILURE = "MFA_VERIFICATION_FAILURE";
export const PRICE_HISTOGRAM_REQUEST = "PRICE_HISTOGRAM_REQUEST";
export const PRICE_HISTOGRAM_SUCCESS = "PRICE_HISTOGRAM_SUCCESS";
export const PRICE_HISTOGRAM_FAILED = "PRICE_HISTOGRAM_FAILED";
export const PRODUCT_REPORT_REQUEST = "PRODUCT_REPORT_REQUEST";
export const PRODUCT_REPORT_SUCCESS = "PRODUCT_REPORT_SUCCESS";
export const PRODUCT_REPORT_FAILED = "PRODUCT_REPORT_FAILED";
export const CREATE_EXPERIMENT_REQUEST = "CREATE_EXPERIMENT_REQUEST";
export const CREATE_EXPERIMENT_SUCCESS = "CREATE_EXPERIMENT_SUCCESS";
export const CREATE_EXPERIMENT_FAILED = "CREATE_EXPERIMENT_FAILED";
export const EXP_AUDIENCE_REQUEST = "EXP_AUDIENCE_REQUEST";
export const EXP_AUDIENCE_SUCCESS = "EXP_AUDIENCE_SUCCESS";
export const EXP_AUDIENCE_FAILED = "EXP_AUDIENCE_FAILED";
export const EXP_CAMPAIGN_REQUEST = "EXP_CAMPAIGN_REQUEST";
export const EXP_CAMPAIGN_SUCCESS = "EXP_CAMPAIGN_SUCCESS";
export const EXP_CAMPAIGN_FAILED = "EXP_CAMPAIGN_FAILED";
export const EXP_LAUNCH_REQUEST = "EXP_LAUNCH_REQUEST";
export const EXP_LAUNCH_SUCCESS = "EXP_LAUNCH_SUCCESS";
export const EXP_LAUNCH_FAILED = "EXP_LAUNCH_FAILED";
export const EXP_BUDGET_REQUEST = "EXP_BUDGET_REQUEST";
export const EXP_BUDGET_SUCCESS = "EXP_BUDGET_SUCCESS";
export const EXP_BUDGET_FAILED = "EXP_BUDGET_FAILED";
export const FETCH_EXPERIMENT_REQUEST = "FETCH_EXPERIMENT_REQUEST";
export const FETCH_EXPERIMENT_SUCCESS = "FETCH_EXPERIMENT_SUCCESS";
export const FETCH_EXPERIMENT_FAILED = "FETCH_EXPERIMENT_FAILED";
export const FETCH_ALL_EXPERIMENTS_LIST_REQUEST =
  "FETCH_ALL_EXPERIMENTS_LIST_REQUEST";
export const FETCH_ALL_EXPERIMENTS_LIST_SUCCESS =
  "FETCH_ALL_EXPERIMENTS_LIST_SUCCESS";
export const FETCH_ALL_EXPERIMENTS_LIST_FAILED =
  "FETCH_ALL_EXPERIMENTS_LIST_FAILED";
export const FETCH_EXPERIMENT_EXECUTION_REQUEST =
  "FETCH_EXPERIMENT_EXECUTION_REQUEST";
export const FETCH_EXPERIMENT_EXECUTION_SUCCESS =
  "FETCH_EXPERIMENT_EXECUTION_SUCCESS";
export const FETCH_EXPERIMENT_EXECUTION_FAILED =
  "FETCH_EXPERIMENT_EXECUTION_FAILED";
export const FETCH_PREDEFINED_EXP_REQUEST = "FETCH_PREDEFINED_EXP_REQUEST";
export const FETCH_PREDEFINED_EXP_SUCCESS = "FETCH_PREDEFINED_EXP_SUCCESS";
export const FETCH_PREDEFINED_EXP_FAILED = "FETCH_PREDEFINED_EXP_FAILED";
export const FETCH_EXP_RESULT_TEST_CONTROL_STATS_REQUEST =
  "FETCH_EXP_RESULT_TEST_CONTROL_STATS_REQUEST";
export const FETCH_EXP_RESULT_TEST_CONTROL_STATS_SUCCESS =
  "FETCH_EXP_RESULT_TEST_CONTROL_STATS_SUCCESS";
export const FETCH_EXP_RESULT_TEST_CONTROL_STATS_FAILED =
  "FETCH_EXP_RESULT_TEST_CONTROL_STATS_FAILED";
export const FETCH_EXP_RESULT_CAMP_STATS_REQUEST =
  "FETCH_EXP_RESULT_CAMP_STATS_REQUEST";
export const FETCH_EXP_RESULT_CAMP_STATS_SUCCESS =
  "FETCH_EXP_RESULT_CAMP_STATS_SUCCESS";
export const FETCH_EXP_RESULT_CAMP_STATS_FAILED =
  "FETCH_EXP_RESULT_CAMP_STATS_FAILED";
export const FETCH_DATA_SOURCE_REQUEST = "FETCH_DATA_SOURCE_REQUEST";
export const FETCH_DATA_SOURCE_SUCCESS = "FETCH_DATA_SOURCE_SUCCESS";
export const FETCH_DATA_SOURCE_FAILED = "FETCH_DATA_SOURCE_FAILED";
export const DELETE_DATA_SOURCE_REQUEST = "DELETE_DATA_SOURCE_REQUEST";
export const DELETE_DATA_SOURCE_SUCCESS = "DELETE_DATA_SOURCE_SUCCESS";
export const DELETE_DATA_SOURCE_FAILED = "DELETE_DATA_SOURCE_FAILED";
export const DELETE_EXPERIMENT_REQUEST = "DELETE_EXPERIMENT_REQUEST";
export const DELETE_EXPERIMENT_SUCCESS = "DELETE_EXPERIMENT_SUCCESS";
export const DELETE_EXPERIMENT_FAILED = "DELETE_EXPERIMENT_FAILED";
export const FETCH_CUSTOMER_MIGRATION_REQUEST =
  "FETCH_CUSTOMER_MIGRATION_REQUEST";
export const FETCH_EXPFETCH_CUSTOMER_MIGRATION_SUCCESS =
  "FETCH_EXPFETCH_CUSTOMER_MIGRATION_SUCCESS";
export const FETCH_CUSTOMER_MIGRATION_FAILED =
  "FETCH_CUSTOMER_MIGRATION_FAILED";
