const CustomerGroup = (type) => ({
    'a': () => {
        return {
            name: "High Value Customers",
            description: "These customers made a purchase recently, have purchased frequently, and tend to spend highly on each transaction.",
            chartName: "High Value",
            reMarketListName: "highvaluecustomerslist",
            reMarketListSuffix: "highvalue_",
            path : "/customer-analytics/a",
            tabName : "a",
        };
    },/*
    'b': () => {
        return {
            name: "Recent Purchasers",
            description: "They purchased very recently and doesn't belong to High Value Group.",
            chartName: "Recent"
        };
    },*/
    'b': () => {
        return {
            name: "Mid Value Customers",
            description: "Those who score moderately on the three factors. This includes everyone who doesn’t qualify for the other three groups.",
            chartName: "Mid Value",
            reMarketListName: "midvaluecustomerslist",
            reMarketListSuffix: "midvalue_",
            path : "/customer-analytics/b",
            tabName : "b",

        };
    },
    'c': () => {
        return {
            name: "Low Value Customers",
            description: "They haven’t made a purchase recently, don’t purchase frequently, and don’t spend much.",
            chartName: "Low Value",
            reMarketListName: "lowvaluecustomerslist",
            reMarketListSuffix: "lowvalue_",
            path : "/customer-analytics/c",
            tabName : "c",

        };
    },
    'all': () => {
        return {
            name: "All Customers",
            description: "All Customers who made a purchase in last 13 months.",
            chartName: "All",
            reMarketListName: "allvaluecustomerslist",
            reMarketListSuffix: " ",
            path : "/customer-analytics/all",
            tabName : "all",

        };
    },
    'riskhvc': () => {
        return {
            name: "Risk High Value Customers",
            description: "High Value Customers with huge time since last purchase.",
            chartName: "Risk HVCs",
            reMarketListName: "riskhighvaluecustomerslist"
        };
    }
})[type];

export default CustomerGroup;

export const ShortMonths = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

export const Colors = ["#F58120", "#00a3ff", "#7c7c82"];

export const channels = ["Direct", "Email", "Organic Search", "Paid Search", "Affiliate", "Social", "Display", "Others", "Referral"];

export const getChannelColor = (channel) => {
    switch (channel) {
        case "Direct":
            return "#74B9FF";
        case "Email":
            return "#FF7675";
        case "Organic Search":
            return "#81ECEC";
        case "Paid Search":
            return "#FEA963";
        case "Affiliate":
            return "#FCAECA";
        case "Social":
            return "#FDCB6E";
        case "Display":
            return "#CD7626";
        case "Others":
            return "#D5A9FF";
        case "Referral":
            return "#FECCA9";
        default:
            return "#000";
    }
}