import {
  Badge,
  Box,
  Button,
  Grid,
  Popover,
  Switch,
  Tab,
  Tabs,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import clsx from "clsx";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import {
  ReportDownloaded,
  a_initial_dates,
  a_month_change,
  all_initial_dates,
  all_month_change,
  b_initial_dates,
  b_month_change,
  c_initial_dates,
  c_month_change,
  month_compare,
  set_a_group_filter,
  set_all_group_filter,
  set_b_group_filter,
  set_c_group_filter,
} from "../../actions/actions";
import { border } from "../../assets/jss/commonStyles";
import "../../assets/jss/customDateRangePicker.css";
import GroupPageStyles from "../../assets/jss/groupPageStyles";
import { GAEventTracker } from "../../index";
import CustomerGroup from "../getGroupDetails";
import Icons from "../icons";
import ASelectPicker, {
  AComparePicker,
  ALeftSelectPicker,
} from "./aDatePickers";
import AllSelectPicker, {
  AllComparePicker,
  AllLeftSelectPicker,
} from "./allDatePickers";
import BSelectPicker, {
  BComparePicker,
  BLeftSelectPicker,
} from "./bDatePickers";
import CSelectPicker, {
  CComparePicker,
  CLeftSelectPicker,
} from "./cDatePickers";
import FilterWindow from "./filterWindow";

export const CustomTabs = withStyles((theme) => ({
  indicator: {
    backgroundColor: theme.palette.secondary.light,
  },
}))(Tabs);
const CustomButton = withStyles((theme) => ({
  root: {
    "&$disabled": {
      backgroundColor: "rgba(0,0,0,0.26)",
      color: theme.palette.common.white,
    },
  },
  disabled: {},
}))(Button);

export const CustomTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    fontSize: "1rem",
    fontWeight: 400,
    paddingRight: 50,
    minWidth: 0,
    color: theme.palette.primary.main,
    "&$selected": {
      color: theme.palette.secondary.light,
    },
  },
  wrapper: {
    flexDirection: "row",
    justifyContent: "left",
  },
  textColorInherit: {
    color: theme.palette.primary.main,
    opacity: 1,
  },
  selected: {
    fontWeight: 700,
  },
}))(Tab);
export const CustomTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontSize: 11,
  },
  arrow: {
    color: theme.palette.primary.main,
  },
}))(Tooltip);

export const CustomBadge = withStyles((theme) => ({
  colorSecondary: {
    backgroundColor: theme.palette.secondary.main,
    color: "white",
  },
}))(Badge);

export const CustomSwitch = withStyles((theme) => ({
  root: {
    width: 35,
    height: 15,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    "&$disabled": {
      opacity: "0.5 !important",
      "& + $track": {
        opacity: "0.5 !important",
      },
    },
    "&$checked": {
      transform: "translateX(20px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: "#FF8735",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#FF8735",
      border: "5px solid #fff",
    },
  },
  thumb: {
    width: 13,
    height: 12,
  },
  track: {
    borderRadius: 35 / 2,
    backgroundColor: "#ACACAC",
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  disabled: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
        disabled: classes.disabled,
      }}
      {...props}
    />
  );
});

function MonthTrendHeader(props) {
  const classes = GroupPageStyles();
  const {
    data,
    ReportDownloaded,
    // groupStart,
    // groupEnd,
    setRemarketDialogOpen,
    dates,
    overview_reducer,
    application_states,
    month_compare,
    handleTabChange,
    a_month_change,
    b_month_change,
    c_month_change,
    all_month_change,
    set_all_group_filter,
    set_a_group_filter,
    set_b_group_filter,
    set_c_group_filter,
    filterStates,
    businessProfile,
    hasLXRTracking,
    trackingReady,
    dataSourceLength
  } = props;

  const selectedSeg = useSelector(
    (state) => state?.application_states?.customerAnalyticsSegmentTabName
  );

  const tabValue = useSelector(
    (state) => state?.application_states?.segmentPageTab
  );

  const monthCompare = application_states.monthCompare;
  const [filterPopperEl, setFilterPopperEl] = React.useState(null);
  const filterPopperOpen = Boolean(filterPopperEl);
  const anchorRef = React.useRef(null);
  const App_URL = process.env.REACT_APP_AppUrl;
  const handlePopperClick = (event) => {
    setFilterPopperEl(filterPopperEl ? null : event.currentTarget);
  };
  const handlePopperClose = () => {
    setFilterPopperEl(null);
  };
  const [state, setState] = useState({
    start: moment(dates[0]),
    end: moment(dates[1]),
  });
  const [compareState, setCompareState] = useState({
    compareStart: moment(dates[2]),
    compareEnd: moment(dates[3]),
  });
  // eslint-disable-next-line

  const { start, end } = state;
  const { compareStart, compareEnd } = compareState;
  const startDate = moment(start, "DD-MM-YYYY");
  const endDate = moment(end, "DD-MM-YYYY");

  const compStartDate = moment(compareStart, "DD-MM-YYYY");
  const compEndDate = moment(compareEnd, "DD-MM-YYYY");

  const compNumDays = compEndDate.diff(compStartDate, "days") + 1;
  const numDays = endDate.diff(startDate, "days") + 1;

  function changeDateFormat(startDate, endDate, numberOfDays) {
    return (
      startDate.format("MMM D, YYYY") +
      " to " +
      endDate.format("MMM D, YYYY") +
      " (" +
      numberOfDays +
      " days)"
    );
  }

  const label = changeDateFormat(start, end, numDays);

  const compareLabel = monthCompare
    ? changeDateFormat(compareStart, compareEnd, compNumDays)
    : "";

  const filter =
    selectedSeg === "all"
      ? filterStates.filterAll
      : selectedSeg === "a"
      ? filterStates.filterA
      : selectedSeg === "b"
      ? filterStates.filterB
      : filterStates.filterC;
  const filterLength = Object.keys(filter).length;
  const handleClearFilter = () => {
    switch (selectedSeg) {
      case "all":
        set_all_group_filter("clear");
        break;
      case "a":
        set_a_group_filter("clear");
        break;
      case "b":
        set_b_group_filter("clear");
        break;
      case "c":
        set_c_group_filter("clear");
        break;
      default:
        return null;
    }
  };
  const theme = useTheme();
  const changeDate = async (
    segment,
    start,
    end,
    compareStart,
    compareEnd,
    takeCompare
  ) => {
    switch (segment) {
      case "a":
        await a_month_change(start, end, compareStart, compareEnd, takeCompare);
        break;
      case "b":
        await b_month_change(start, end, compareStart, compareEnd, takeCompare);
        break;
      case "c":
        await c_month_change(start, end, compareStart, compareEnd, takeCompare);
        break;
      case "all":
        await all_month_change(
          start,
          end,
          compareStart,
          compareEnd,
          takeCompare
        );
        break;
      default:
        return null;
    }
  };
  const handleCallback = (start, end, initial = false) => {
    setState({ start, end });
    if (!initial) {
      changeDate(
        selectedSeg,
        new Date(start),
        new Date(end),
        dates[2],
        dates[3],
        false
      );
    }
  };
  const handleCompareCallback = (compareStart, compareEnd, initial = false) => {
    if (!initial) {
      changeDate(
        selectedSeg,
        dates[0],
        dates[1],
        new Date(compareStart),
        new Date(compareEnd),
        true
      );
    } else {
      setCompareState({ compareStart, compareEnd });
    }
  };

  useEffect(() => {
    handleCallback(moment(dates[0]), moment(dates[1]), true);
    handleCompareCallback(moment(dates[2]), moment(dates[3]), true);
    //eslint-disable-next-line
  }, [props]);

  const handleCompareChange = (e) => {
    month_compare(e.target.checked);
    changeDate(selectedSeg, dates[0], dates[1], dates[2], dates[3], false);
  };
  const setCompareDates = async (event, picker) => {
    await picker.setStartDate(moment(dates[2]).toDate());
    await picker.setEndDate(moment(dates[3]).toDate());
    picker.updateView();
  };
  const setSelectDates = async (event, picker) => {
    await picker.setStartDate(moment(dates[0]).toDate());
    await picker.setEndDate(moment(dates[1]).toDate());
    picker.oldStartDate = picker.startDate.clone();
    picker.oldEndDate = picker.endDate.clone();
    picker.updateView();
  };

  return new Date(end).toDateString() !== new Date().toDateString() ? (
    <div
      id={"daterangepicker"}
      className={clsx(classes.overviewStyles)}
      style={{
        position: "sticky",
        top: 118,
        zIndex: 9,
        margin: 8,
      }}
    >
      <Box border={border} className={classes.dateheader}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid
            container
            spacing={1}
            md={monthCompare ? 3 : 6}
            style={{ padding: "0 0 0 0.7rem" }}
          >
            <Grid item>{Icons?.cartIconImg}</Grid>
            <Grid item>
              <Typography
                variant={"body1"}
                color={"primary"}
                style={{
                  fontWeight: 600,
                  fontFamily: "Lato, sans-serif",
                }}
              >
                Sales Analytics :
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            md={3.5}
            style={{
              padding: "15px 8px 8px 10px",
              left: "-10px",
              position: "relative",
            }}
          >
            <Box
              className={"flex"}
              id={data.segment === "a" ? "tour10thStep" : null}
            >
              <Typography
                variant={"h5"}
                style={{
                  color: theme.palette.secondary.light,
                  fontWeight: 700,
                }}
              >
                Selected Period&emsp;
              </Typography>
              <Box
                width={8}
                height={8}
                borderRadius="2px"
                style={{
                  backgroundColor: theme.palette.secondary.light,
                  marginTop: "5px",
                }}
              />
            </Box>

            {selectedSeg === "all" ? (
              monthCompare ? (
                <AllSelectPicker
                  onShow={setSelectDates}
                  classes={classes}
                  start={start}
                  end={end}
                  label={label}
                  overviewData={overview_reducer.data}
                  onCallBack={handleCallback}
                />
              ) : (
                <AllLeftSelectPicker
                  onShow={setSelectDates}
                  classes={classes}
                  start={start}
                  end={end}
                  label={label}
                  overviewData={overview_reducer.data}
                  onCallBack={handleCallback}
                />
              )
            ) : selectedSeg === "a" ? (
              monthCompare ? (
                <ASelectPicker
                  onShow={setSelectDates}
                  classes={classes}
                  start={start}
                  end={end}
                  label={label}
                  overviewData={overview_reducer.data}
                  onCallBack={handleCallback}
                />
              ) : (
                <ALeftSelectPicker
                  onShow={setSelectDates}
                  classes={classes}
                  start={start}
                  end={end}
                  label={label}
                  overviewData={overview_reducer.data}
                  onCallBack={handleCallback}
                />
              )
            ) : selectedSeg === "b" ? (
              monthCompare ? (
                <BSelectPicker
                  onShow={setSelectDates}
                  classes={classes}
                  start={start}
                  end={end}
                  label={label}
                  overviewData={overview_reducer.data}
                  onCallBack={handleCallback}
                />
              ) : (
                <BLeftSelectPicker
                  onShow={setSelectDates}
                  classes={classes}
                  start={start}
                  end={end}
                  label={label}
                  overviewData={overview_reducer.data}
                  onCallBack={handleCallback}
                />
              )
            ) : monthCompare ? (
              <CSelectPicker
                onShow={setSelectDates}
                classes={classes}
                start={start}
                end={end}
                label={label}
                overviewData={overview_reducer.data}
                onCallBack={handleCallback}
              />
            ) : (
              <CLeftSelectPicker
                onShow={setSelectDates}
                classes={classes}
                start={start}
                end={end}
                label={label}
                overviewData={overview_reducer.data}
                onCallBack={handleCallback}
              />
            )}
          </Grid>
          {monthCompare ? (
            <Grid
              item
              md={3.5}
              style={{
                padding: "15px 8px 8px 10px",
                borderLeft: border,
                left: "-10px",
                position: "relative",
              }}
            >
              <Box className={"flex"}>
                <Typography
                  variant={"h5"}
                  style={{
                    color: theme.palette.primary.main,
                    opacity: 0.5,
                  }}
                >
                  {monthCompare ? "Compared With" : null}
                  &emsp;{monthCompare ? <></> : null}
                </Typography>
                <Box
                  width={8}
                  height={8}
                  borderRadius="2px"
                  style={{
                    backgroundColor: theme.palette.primary.main,
                    opacity: 0.2,
                    marginTop: "5px",
                  }}
                />
              </Box>

              {monthCompare ? (
                selectedSeg === "all" ? (
                  <AllComparePicker
                    onShow={setCompareDates}
                    classes={classes}
                    start={dates[2]}
                    end={dates[3]}
                    label={compareLabel}
                    overviewData={overview_reducer.data}
                    onCallBack={handleCompareCallback}
                  />
                ) : selectedSeg === "a" ? (
                  <AComparePicker
                    onShow={setCompareDates}
                    classes={classes}
                    start={dates[2]}
                    end={dates[3]}
                    label={compareLabel}
                    overviewData={overview_reducer.data}
                    onCallBack={handleCompareCallback}
                  />
                ) : selectedSeg === "b" ? (
                  <BComparePicker
                    onShow={setCompareDates}
                    classes={classes}
                    start={dates[2]}
                    end={dates[3]}
                    label={compareLabel}
                    overviewData={overview_reducer.data}
                    onCallBack={handleCompareCallback}
                  />
                ) : (
                  <CComparePicker
                    onShow={setCompareDates}
                    classes={classes}
                    start={dates[2]}
                    end={dates[3]}
                    label={compareLabel}
                    overviewData={overview_reducer.data}
                    onCallBack={handleCompareCallback}
                  />
                )
              ) : null}
            </Grid>
          ) : null}
          <Grid
            item
            md={1}
            className={"flex"}
            style={{
              borderLeft: border,
              padding: "24px 8px 8px 8px",
              left: "-20px",
              position: "relative",
            }}
          >
            <Typography
              variant={"h5"}
              style={{ marginTop: "5px", fontWeight: "500" }}
            >
              Compare
            </Typography>
            <CustomSwitch
              checked={monthCompare}
              onChange={(e) => {
                handleCompareChange(e);
                GAEventTracker(
                  CustomerGroup(selectedSeg)().name,
                  "Comparision Button",
                  e.target.checked
                );
              }}
              name="monthSelector"
            />
          </Grid>
          <Grid
            item
            md={1}
            style={{ borderLeft: "1px solid #BBBBBB", display: "flex" }}
          >
            {!Boolean(filterLength) ? null : (
              <CustomButton
                disabled={tabValue === "convPaths" || tabValue === "product"}
                onClick={() => {
                  handleClearFilter();
                  GAEventTracker(
                    CustomerGroup(selectedSeg)().name,
                    "Graph Filter Clear"
                  );
                }}
                style={{ minWidth: 0, borderRadius: 0 }}
              >
                <Typography
                  style={{
                    textDecoration: "underline",
                    textTransform: "none",
                  }}
                  variant={"body1"}
                >
                  Clear
                </Typography>
              </CustomButton>
            )}
            {!Boolean(filterLength) ? (
              <CustomButton
                id={data.segment === "a" ? "tour11thStep" : null}
                disabled={tabValue === "convPaths" || tabValue === "product"}
                ref={anchorRef}
                onClick={(e) => {
                  handlePopperClick(e);
                  GAEventTracker(
                    CustomerGroup(selectedSeg)().name,
                    "Graph Filter Popup"
                  );
                }}
                style={{ minWidth: 0, borderRadius: 0 }}
              >
                <Typography
                  style={{
                    textTransform: "none",
                  }}
                  variant={"body1"}
                >
                  Filters
                </Typography>
                &ensp;&nbsp;&ensp;
                <CustomBadge
                  badgeContent={filterLength}
                  invisible={!Boolean(filterLength)}
                  color={"secondary"}
                >
                  {tabValue === "convPaths" || tabValue === "product"
                    ? Icons.newWhiteFilterIcon
                    : Icons.newFilterIcon}
                </CustomBadge>
              </CustomButton>
            ) : (
              <CustomButton
                id={data.segment === "a" ? "tour11thStep" : null}
                disabled={tabValue === "convPaths" || tabValue === "product"}
                ref={anchorRef}
                onClick={(e) => {
                  handlePopperClick(e);
                  GAEventTracker(
                    CustomerGroup(selectedSeg)().name,
                    "Graph Filter Popup"
                  );
                }}
                style={{ minWidth: 0, borderRadius: 0 }}
              >
                <CustomBadge
                  badgeContent={filterLength}
                  invisible={!Boolean(filterLength)}
                  color={"secondary"}
                >
                  {tabValue === "convPaths" || tabValue === "product"
                    ? Icons.newWhiteFilterIcon
                    : Icons.newFilterIcon}
                </CustomBadge>
              </CustomButton>
            )}
          </Grid>
        </Grid>
      </Box>
      <Box style={{ backgroundColor: "#ffffff" }}>
        <CustomTabs value={tabValue} onChange={handleTabChange}>
          <CustomTab
            onClick={() => {
              GAEventTracker(
                CustomerGroup(selectedSeg)().name,
                "Property Tab",
                "Customers"
              );
            }}
            value={"customers"}
            icon={
              <>
                <div>
                  {tabValue === "customers"
                    ? Icons.selectedCustomerTabIcon
                    : Icons.customerTabIcon}
                </div>
                &ensp;Customers
              </>
            }
          />
          <CustomTab
            onClick={() => {
              GAEventTracker(
                CustomerGroup(selectedSeg)().name,
                "Property Tab",
                "Orders"
              );
            }}
            value={"orders"}
            icon={
              <>
                <div>
                  {tabValue === "orders"
                    ? Icons.selectedOrderTabIcon
                    : Icons.orderTabIcon}
                </div>
                &ensp;Orders
              </>
            }
          />
          <CustomTab
            id={data.segment === "a" ? "tour9thStep" : null}
            onClick={() => {
              GAEventTracker(
                CustomerGroup(selectedSeg)().name,
                "Property Tab",
                "Revenue"
              );
            }}
            value={"revenue"}
            icon={
              <>
                <div>
                  {tabValue === "revenue"
                    ? Icons.selectedRevenueTabIcon
                    : Icons.revenueTabIcon}
                </div>
                &ensp;Revenue
              </>
            }
          />
          {/*{businessProfile.ecom ? null : (*/}
            <CustomTab
              onClick={() => {
                GAEventTracker(
                  CustomerGroup(selectedSeg)().name,
                  "Property Tab",
                  "Conversion Paths"
                );
              }}
              value={"convPaths"}
              icon={
                <>
                  <div>
                    {tabValue === "convPaths"
                      ? Icons.selectedConvPathTabIcon
                      : Icons.convPathTabIcon}
                  </div>
                  &ensp;Conversion Path
                </>
              }
            />
          {/*)}*/}
          <CustomTab
            onClick={() => {
              GAEventTracker(
                CustomerGroup(selectedSeg)().name,
                "Property Tab",
                "Product"
              );
            }}
            value={"product"}
            icon={
              <>
                <div>
                  {tabValue === "product"
                    ? Icons.selectedProductTabIcon
                    : Icons.productTabIcon}
                </div>
                &ensp;Product
              </>
            }
          />
        </CustomTabs>
      </Box>
      <Popover
        open={filterPopperOpen}
        anchorEl={anchorRef.current}
        onClose={handlePopperClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <FilterWindow
          segment={selectedSeg}
          hasLXRTracking={hasLXRTracking}
          dataSourceLength={dataSourceLength}
          trackingReady={trackingReady}
          handlePopperClose={handlePopperClose}
        />
      </Popover>
    </div>
  ) : null;
}
const mapStateToProps = (state) => ({
  application_states: state.application_states,
  filterStates: state.filterStates,
  overview_reducer: state.overview_reducer,
  businessProfile: state.businessProfile,
});

const mapDispatchToProps = (dispatch) => ({
  month_compare: (value) => dispatch(month_compare(value)),
  a_month_change: (start, end, compareStart, compareEnd, takeCompare) =>
    dispatch(a_month_change(start, end, compareStart, compareEnd, takeCompare)),
  b_month_change: (start, end, compareStart, compareEnd, takeCompare) =>
    dispatch(b_month_change(start, end, compareStart, compareEnd, takeCompare)),
  c_month_change: (start, end, compareStart, compareEnd, takeCompare) =>
    dispatch(c_month_change(start, end, compareStart, compareEnd, takeCompare)),
  all_month_change: (start, end, compareStart, compareEnd, takeCompare) =>
    dispatch(
      all_month_change(start, end, compareStart, compareEnd, takeCompare)
    ),
  set_all_group_filter: (filterName, filterValue) =>
    dispatch(set_all_group_filter(filterName, filterValue)),
  set_a_group_filter: (filterName, filterValue) =>
    dispatch(set_a_group_filter(filterName, filterValue)),
  set_b_group_filter: (filterName, filterValue) =>
    dispatch(set_b_group_filter(filterName, filterValue)),
  set_c_group_filter: (filterName, filterValue) =>
    dispatch(set_c_group_filter(filterName, filterValue)),
  a_initial_dates: (duration) => dispatch(a_initial_dates(duration)),
  b_initial_dates: (duration) => dispatch(b_initial_dates(duration)),
  c_initial_dates: (duration) => dispatch(c_initial_dates(duration)),
  all_initial_dates: (duration) => dispatch(all_initial_dates(duration)),
  ReportDownloaded: (report_filename, report_type, segment) =>
    dispatch(ReportDownloaded(report_filename, report_type, segment)),
  // business_profile: () => dispatch(business_profile()),
});
export default connect(mapStateToProps, mapDispatchToProps)(MonthTrendHeader);
