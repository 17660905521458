import React, {useEffect} from "react"
import {business_profile, clearMFAVerfiyStatus, get_user_data, handle_login} from "../actions/actions";
import {connect} from "react-redux";
import HomePage from "../pages/homePage"
import OverviewPage from "../pages/overviewPage"
import TermsOfUse from "./termsAndConditions/termsOfUse"
import moment from "moment"

export const redirectToPage = (url) => {
    window.location.hash = url;
    return null
};

function User(props) {
    const {loginAuthentication, userProfile, get_user_data,businessProfile,business_profile,MFAVerficationReducer,clearMFAVerfiyStatus} = props;
    const queryString = window.location.search;
    const url = window.location.href;
    const urlParams = new URLSearchParams(queryString);
    const page = urlParams.get('page');
    const state = urlParams.get('state');
    const code = urlParams.get('code');
    const mfaEnable = localStorage.getItem('MFAEnable');
    const mfaVerify = localStorage.getItem('MFAVerified');

    useEffect(() => {
        if (loginAuthentication.logged_in || localStorage.getItem('access') ) {
            if(mfaEnable === "true"){
                if(mfaVerify === "true"){
                    get_user_data();
                    business_profile();
            }
        }
        else if(mfaEnable === "false" || mfaEnable === null){
            get_user_data();
            business_profile();
        }
        //eslint-disable-next-line
    }}, [loginAuthentication, localStorage]);

    useEffect(() => {
    }, [props]);

    async function ReDirector(url, state, code, redirectURL) {
        if (localStorage.getItem('source') === 'facebookads') {
            window.location.hash = ''
        }
        localStorage.setItem('currentURl', url);
        localStorage.setItem('state', state);
        localStorage.setItem('code', code);
        localStorage.setItem('redirectURL', redirectURL);
        const createRedirectURL = window.location.port ? "http://" + window.location.hostname + ":" + window.location.port + "/#/connections" : "https://" + window.location.hostname + "/#/connections";
        window.location.assign(createRedirectURL);
        // await history.replace('/#/settings')
        // await window.history.pushState({}, document.title, "/#/settings")
        return null
    }

    async function ReDirectorIntegrator(url, code, redirectURL) {
        // if (localStorage.getItem('source') === 'integrations') {

        // }
        localStorage.setItem('currentURl', url);
        localStorage.setItem('code', code);
        localStorage.setItem('redirectURL', redirectURL);
        const createRedirectURL = window.location.port ? "http://" + window.location.hostname + ":" + window.location.port + "/#/integrations" : "https://" + window.location.hostname + "/#/integrations";
        window.location.assign(createRedirectURL);
        // await history.replace('/#/settings')
        // await window.history.pushState({}, document.title, "/#/settings")
        return null
    }

    async function ReDirectorSignup(url, code, redirectURL, shop) {
        // if (localStorage.getItem('source') === 'integrations') {

        // }
        localStorage.setItem('currentURL', url);
        localStorage.setItem('code', code);
        localStorage.setItem('redirectURL', redirectURL);
        localStorage.setItem('shop', shop);
        const createRedirectURL = window.location.port ? "http://" + window.location.hostname + ":" + window.location.port + "/#/signup" : "https://" + window.location.hostname + "/#/signup";
        window.location.assign(createRedirectURL);
        // await history.replace('/#/settings')
        // await window.history.pushState({}, document.title, "/#/settings")
        return null
    }
    if (page === 'connections') {
        const redirectURL = window.location.port ? "http://" + window.location.hostname + ":" + window.location.port + "/?page=connections" : "https://" + window.location.hostname + "/?page=connections";
        ReDirector(url, state, code, redirectURL)
        // history.push("/settings")
        // history.replaceState('', '', '/settings')
        // Redirector('/settings')
    } 
    else if (page === 'integrations') {
        const redirectURL = window.location.port ? "http://" + window.location.hostname + ":" + window.location.port + "/?page=integrations" : "https://" + window.location.hostname + "/?page=integrations";
        ReDirectorIntegrator(url, code, redirectURL)
        // history.push("/settings")
        // history.replaceState('', '', '/settings')
        // Redirector('/settings')
    }
    else if (page === 'signup') {
        const redirectURL = window.location.port ? "http://" + window.location.hostname + ":" + window.location.port + "/?page=signup" : "https://" + window.location.hostname + "/?page=signup";
        const shop = urlParams.get('shop');
        ReDirectorSignup(url, code, redirectURL, shop)
        // history.push("/settings")
        // history.replaceState('', '', '/settings')
        // Redirector('/settings')
    }

    useEffect(()=>{
        if(!MFAVerficationReducer.isLoading && MFAVerficationReducer.code){
            clearMFAVerfiyStatus()
        }
    },[MFAVerficationReducer])


    
    return (
        loginAuthentication.logged_in || localStorage.getItem('access') ?
                mfaEnable === "true"?
                    mfaVerify === "true" ?
                        userProfile.first_name ?
                        userProfile.terms_accepted === false ?
                            <TermsOfUse/>
                            :
                            userProfile.email_confirmation_date ?
                                userProfile.bu_id_is_active ?
                                    userProfile.last_clustered ?
                                            <OverviewPage/>
                                    :
                                    !businessProfile.isLoading && !businessProfile.ecom?
                                        moment(new Date()).diff(new Date(userProfile.bu_id_date_created), "days") > 2 ?
                                            redirectToPage('/review_data_status')
                                            :
                                            redirectToPage('/tracking_script')
                                        :
                                        redirectToPage('/review_data_status')
                                :
                                redirectToPage('/add_details')
                            :
                            redirectToPage('/email_activation')
                        : null 
                    :
                    redirectToPage('/mfa_verification')
                :
                userProfile.first_name ?
                    userProfile.terms_accepted === false ?
                        <TermsOfUse/>
                        :
                        userProfile.email_confirmation_date ?
                            userProfile.bu_id_is_active ?
                                userProfile.last_clustered ?
                                        <OverviewPage/>
                                :
                                !businessProfile.isLoading && !businessProfile.ecom?
                                    moment(new Date()).diff(new Date(userProfile.bu_id_date_created), "days") > 2 ?
                                        redirectToPage('/review_data_status')
                                        :
                                        redirectToPage('/tracking_script')
                                    :
                                    redirectToPage('/review_data_status')
                            :
                            redirectToPage('/add_details')
                        :
                        redirectToPage('/email_activation')
                : null 
        :
        <HomePage/> 
    )
}


// function ShopifyUser(props) {
//     const {loginAuthentication, userProfile, add_shopify_details} = props;
//     const queryString = window.location.search;
//     // const url = window.location.href;
//     const urlParams = new URLSearchParams(queryString);
//     const page = urlParams.get('page');
//     // const state = urlParams.get('state');
//     // const code = urlParams.get('code');
//     useEffect(() => {
//         if (loginAuthentication.logged_in || localStorage.getItem('access')) {
//             add_shopify_details()
//         }
//         //eslint-disable-next-line
//     }, [loginAuthentication, localStorage]);
//     useEffect(() => {
//     }, [props]);

//     if (page === 'integrations') {
//         const redirectURL = window.location.port ? "http://" + window.location.hostname + ":" + window.location.port + "/?page=integrations" : "https://" + window.location.hostname + "/?page=integrations";
//         // ReDirector(url, state, code, redirectURL)
//         // history.push("/settings")
//         // history.replaceState('', '', '/settings')
//         // Redirector('/settings')
//     }
//     return (
//         loginAuthentication.logged_in || localStorage.getItem('access') ?
//             userProfile.first_name ?
//                 userProfile.terms_accepted === false ?
//                     <TermsOfUse/>
//                     :
//                     userProfile.email_confirmation_date ?
//                         userProfile.bu_id ?
//                             add_shopify_details.store_url ?
//                                 userProfile.last_clustered ?
//                                     <OverviewPage/>
//                                 :
//                                 redirectToPage('/add_details')
//                             : 
//                             redirectToPage('/integrations')
//                         : null :
//                         redirectToPage('/email_activation')
//                 : null :
//           <HomePage/>
//     )
// }

const mapStateToProps = (state) => ({
    loginAuthentication: state.loginAuthentication,
    userProfile: state.userProfile,
    businessProfile: state.businessProfile,
    MFAVerficationReducer:state.MFAVerficationReducer,
});
const mapDispatchToProps = (dispatch) => ({
    handle_login: (e, data) => dispatch(handle_login(e, data)),
    get_user_data: () => dispatch(get_user_data()),
    business_profile: () => dispatch(business_profile()),
    clearMFAVerfiyStatus:()=> dispatch(clearMFAVerfiyStatus())
});
export default connect(mapStateToProps, mapDispatchToProps)(User);