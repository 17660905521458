import {store} from "../../App";
import {PRODUCT_TOUR_RUN_END} from "../../actions/actionTypes";

export const tour = {
    id: 'hello-hopscotch',
    onEnd: async function () {
        window.hopscotch.endTour(true)
        await store.dispatch({type: PRODUCT_TOUR_RUN_END})
        window.location.hash = '/'
    },
    /*onStart: async function () {
        await store.dispatch({type: PRODUCT_TOUR_RUN_START})
        document.getElementsByClassName("hopscotch-bubble-number").innerHTML = "";
    },*/
    onClose: async function () {
        // alert("onclose");
        window.hopscotch.endTour(true)
        await store.dispatch({type: PRODUCT_TOUR_RUN_END});
        window.location.hash = '/'
    },
    onError: async function(){
        window.hopscotch.endTour(true)
        await store.dispatch({type: PRODUCT_TOUR_RUN_END});
        window.location.hash = '/'
    },
    steps: [
        {
            target: 'tour1stStep',
            title: '',
            content: 'The side menu lets you navigate anywhere in LXRInsights. The Customers section provides performance analytics. The Audience section allows you to push audiences to different ad platforms and view audience analytics. The Predictions section provides insights on predicted customer behavior.',
            placement: 'right',
            delay: 100,
            onShow: async () => {
                document.getElementsByClassName("hopscotch-bubble-number").innerHTML = "";
            },
            highlight: {
                disableClick: true,
            }
        },
        {
            target: 'tour2ndStep',
            title: '',
            content: 'Information icons give helpful tips to better understand the information you are viewing.',
            placement: 'bottom',
            xOffset: -10,
            yOffset: -10,
            highlight: {
                disableClick: true,
            }
        },
        {
            target: 'tour3rdStep',
            title: '',
            content: 'The wand provides AI-generated insights about your data.',
            placement: 'bottom',
            arrowOffset: 15,
            highlight:{
                disableClick:true,
            }
        },
        {
            target: 'tour4thStep',
            title: '',
            content: 'LXRInsights runs off of the last 13 months of data. This date range cannot be changed. However, you can view different ranges within the 13 month period on the Customers page; changeable dates are indicated in orange.',
            placement: 'bottom',
            arrowOffset: 'center',
            yOffset: -10,
            highlight:{
                disableClick:true,
            }
        },
        {
            target: 'tour5thStep',
            title: '',
            content: 'The Pie Chart is interactive when you hover over the slices.',
            placement: 'bottom',
            arrowOffset: 'center',
            yOffset: -150,
            xOffset: 100,
            highlight:{
                disableClick:true,
            }
        },
        {
            target: 'tour6thStep',
            title: '',
            content: 'The help icon provides the options to <ul><li>Go through help guides (FAQs) - where you can find definitions and examples of how to use the tool</li><li>Go through Product Tour</li><li>Contact Us</li>',
            placement: 'left',
            xOffset: 20,
            yOffset: 20,
            multipage: true,
            onNext: async () => {
                window.location.hash = "/groups/a";
            },
            highlight:{
                disableClick:true,
            }
        },
        {
            target: 'tour7thStep',
            title: '',
            content: 'The Download icon allows you to download the audience containing hashed email addresses of customers for uploading it to other marketing platforms. The Re-market icon allows you to push the audience to connected marketing platforms in just a few clicks.',
            placement: 'bottom',
            onPrev: async () => {
                window.location.hash = "/";
            },
            highlight:{
                disableClick:true,
            }
            // xOffset: -10
        },
        {
            target: 'tour8thStep',
            title: '',
            content: 'Key insights shares valuable data about your sales. Each topic is clickable for more specific information.',
            placement: 'bottom',
            highlight:{
                disableClick:true,
            }
        },
        {
            target: 'tour9thStep',
            title: '',
            content: 'These headers are clickable to give you trend data analytics for different aspects of your business.',
            placement: 'bottom',
            highlight:{
                disableClick:true,
            }
        },
        {
            target: 'tour10thStep',
            title: '',
            content: 'When you see Selected Period in orange that means you can change the date range of the data.',
            placement: 'bottom',
            yOffset: 10,
            highlight:{
                disableClick:true,
            }
        },
        {
            target: 'tour11thStep',
            title: '',
            content: 'Add or remove filters to find more targeted analytics.',
            placement: 'left',
            multipage: true,
            onNext: async () => {
                window.location.hash = "/customers"
            },
            highlight:{
                disableClick:true,
            }
        },
        {
            target: 'tour12thStep',
            title: '',
            content: 'Re-Market to: header tabs are clickable so you can switch between top level audiences and see the second level filters.',
            placement: 'bottom',
            onPrev: async () => {
                window.location.hash = "/groups/a";
            },
            highlight:{
                disableClick:true,
            }
        },
        {
            target: 'tour13thStep',
            title: '',
            content: 'Predefined audiences allow you to select LXRI-created audiences for more targeted marketing campaigns. You can see your customers who are at risk or belong to a prediction-based audience.',
            placement: 'bottom',
            highlight:{
                disableClick:true,
            }
        },
        {
            target: 'tour14thStep',
            title: '',
            content: 'Create your own audience segments for re-marketing with filters.',
            placement: 'bottom',
            xOffset: -250,
            arrowOffset: 250,
            highlight:{
                disableClick:true,
            }
        },
        {
            target: 'tour15thStep',
            title: '',
            content: 'Re-Market is where you push your target audiences to your marketing platform like Google Ads or Meta Ads.',
            placement: 'bottom',
            xOffset: -225,
            arrowOffset: 225,
            multipage: true,
            onNext: async () => {
                window.location.hash = "/audienceAnalytics"
            },
            highlight:{
                disableClick:true,
            }
        },
        {
            target: 'tour16thStep',
            title: '',
            content: 'Audience analytics shares valuable data about the performance of your audiences that have been pushed to your marketing platforms.',
            placement: 'bottom',
            multipage: true,
            onNext: async () => {
                window.location.hash = "/connections"
            },
            onPrev: async () => {
                window.location.hash = "/customers"
            },
            highlight:{
                disableClick:true,
            }
        },
        {
            target: 'tour17thStep',
            title: '',
            content: 'Connections allow you to add your Google and Meta Ads accounts so that LXRI can automatically push target audiences to those platforms on a recurring basis. This helps you easily target high-value, at-risk, and other important audience segments.',
            placement: 'bottom',
            xOffset: -225,
            arrowOffset: 225,
            multipage: true,
            onNext: async () => {
                window.location.hash = "/predictions"
            },
            onPrev: async () => {
                window.location.hash = "/audienceAnalytics"
            },
            highlight:{
                disableClick:true,
            }
        },
        {
            target: 'tour18thStep',
            title: '',
            content: 'The Predictions page provides insights from LXRI\'s AI for returning single buyers, customers who are likely to return in the next 2 months, and their predicted conversion value.',
            placement: 'bottom',
            onPrev: async () => {
                localStorage.setItem('tourStep', '17')
                window.location.hash = "/connections"
            },
            highlight:{
                disableClick:true,
            }
        },
        {
            target: 'tour19thStep',
            title: '',
            content: 'You are able to push any of these audience segments directly to your Google Ads or Meta Ads platform from here.',
            placement: 'bottom',
            highlight:{
                disableClick:true,
            }
        },
        {
            target: 'tour20thStep',
            title: '',
            content: 'The slider allows you to dial in and specifically target a segment of your shoppers within the AI-generated audience.',
            placement: 'top',
            highlight:{
                disableClick:true,
            }
        },
    ],
    showPrevButton: !0,
    scrollDuration: 500,
    showCloseButton: !0,
    bubblePadding: 0,
    skipIfNoElement: true,
    bubbleWidth: 320,
    i18n: {
        closeTooltip: 'Skip Tour',
        skipBtn: 'Skip Tour'
    }
    // scrollTopMargin: 100
};