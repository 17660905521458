import React, { useEffect } from "react";
import { Box, Grid, Tooltip, Typography } from "@mui/material";
import { border } from "../../assets/jss/commonStyles";
import CustomerGroup from "../getGroupDetails";
import Icons from "../icons";
import { Link } from "react-router-dom";
import clsx from "clsx";
import NumericLabel from "react-pretty-numbers";
import { GAEventTracker } from "../../index";
import { connect, useDispatch } from "react-redux";
import {
  business_profile,
  customerAnalyticsSegmentName,
} from "../../actions/actions";
import * as _ from "lodash";
import moment from "moment";

const DataBox = (props) => {
  const { title, value, percent } = props;
  return (
    <Box
      border={border}
      borderRadius="6px"
      boxShadow={"4px 4px 18px rgba(0, 0, 0, 0.06)"}
      p={2}
      style={{ textAlign: "center" }}
    >
      {percent ? (
        <Typography
          variant={"h2"}
          color={"primary"}
          style={{ fontWeight: 700 }}
        >
          {value} ({percent})
        </Typography>
      ) : (
        <Typography
          variant={"h2"}
          color={"primary"}
          style={{ fontWeight: 700 }}
        >
          {/*<b style={wordBreak ? { wordBreak: "break-all" } : {}}>*/}
          {value}
          {/*</b>*/}
        </Typography>
      )}
      <Typography variant={"body1"} color={"primary"}>
        {title}
      </Typography>
    </Box>
  );
};

const BlockHeader = (props) => {
  const {
    segmentDetail,
    country_code,
    data,
    currency,
    businessProfile,
    business_profile,
    dataStatusOrderReducer,
    dataStatusOrderItemsReducer,
    dataStatusTrackingInfoReducer,
    data_status_order,
    data_status_order_item,
    data_status_tracking_info,
  } = props;

  const dispatch = useDispatch();

  const paramOptions1 = {
    commafy: true,
    wholenumber: "ceil",
    shortFormat: true,
    shortFormatMinValue: 10000,
    justification: "L",
    precision: 1,
  };
  const paramOptions5 = {
    commafy: true,
    wholenumber: "ceil",
    shortFormat: true,
    shortFormatMinValue: 10000,
    justification: "L",
  };
  const paramOptions2 = {
    precision: 1,
    commafy: true,
    justification: "L",
  };
  const paramOptions3 = {
    wholenumber: "ceil",
    locales: "en-US",
    currency: true,
    currencyIndicator: currency,
    commafy: true,
    justification: "L",
  };
  const paramOptions4 = {
    wholenumber: "ceil",
    justification: "L",
  };
  const hasLXRTracking =
    !businessProfile.isLoading &&
    businessProfile.bu_id &&
    _.some(businessProfile.data_sources, ["data_source_type", "tracking"]);

  const dataSourceLength =
    !businessProfile.isLoading && businessProfile.bu_id
      ? businessProfile.data_sources.length
      : null;

  const trackingReady = hasLXRTracking
    ? !businessProfile.isLoading &&
      businessProfile.bu_id &&
      businessProfile.data_sources.some((val) => {
        if (val.data_source_type === "tracking") {
          const dateReady = moment(val.date_ready);
          const startOfMonth = moment().startOf("month");
          return val.date_ready
            ? dateReady >= startOfMonth
              ? false
              : true
            : false;
        }
        return null;
      })
    : null;

  //  useEffect(()=>{
  //   business_profile()
  //  },[])

  return (
    <React.Fragment>
      <Box p={1.5}>
        <Grid container alignItems={"center"}>
          <Grid item xs={12}>
            <Grid container alignItems={"center"}>
              <Grid item>
                <Link
                  to={`${CustomerGroup(segmentDetail.segment)().path}`}
                  className={clsx("text-decoration-none")}
                >
                  <Typography
                    color={"primary"}
                    variant={"h4"}
                    style={{ fontWeight: 700, lineHeight: "22px" }}
                    onClick={() => {
                      dispatch(
                        customerAnalyticsSegmentName({
                          customerAnalyticsSegmentTabName: CustomerGroup(
                            segmentDetail.segment
                          )().tabName,
                        })
                      );

                      GAEventTracker(
                        "Overview",
                        "Title Click",
                        CustomerGroup(segmentDetail.segment)().name
                      );
                    }}
                  >
                    {CustomerGroup(segmentDetail.segment)().name}&ensp;
                  </Typography>
                </Link>
              </Grid>
              <Grid item>
                <Tooltip
                  title={CustomerGroup(segmentDetail.segment)().description}
                >
                  <Typography
                    id={segmentDetail.segment === "all" ? "tour2ndStep" : null}
                    variant={"body2"}
                    style={{ marginTop: "5px" }}
                  >
                    {Icons.infoCircleIconOutline}
                  </Typography>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container alignItems={"center"} spacing={2}>
          <Grid item md>
            {segmentDetail.customers < 10000 ? (
              <DataBox
                title={
                  country_code
                    ? `Total Customers (${country_code})`
                    : "Total Customers"
                }
                value={
                  <NumericLabel params={paramOptions5}>
                    {segmentDetail.customers}
                  </NumericLabel>
                }
                percent={
                  segmentDetail.segment !== "all"
                    ? `${Math.round(
                        (segmentDetail.customers * 100) / data.totalCustomers
                      )}%`
                    : false
                }
              />
            ) : (
              <DataBox
                title={
                  country_code
                    ? `Total Customers (${country_code})`
                    : "Total Customers"
                }
                value={
                  <NumericLabel params={paramOptions1}>
                    {segmentDetail.customers}
                  </NumericLabel>
                }
                percent={
                  segmentDetail.segment !== "all"
                    ? `${Math.round(
                        (segmentDetail.customers * 100) / data.totalCustomers
                      )}%`
                    : false
                }
              />
            )}
          </Grid>
          <Grid item md>
            {segmentDetail.orders < 10000 ? (
              <DataBox
                title={"Total Orders"}
                value={
                  <NumericLabel params={paramOptions5}>
                    {segmentDetail.orders}
                  </NumericLabel>
                }
              />
            ) : (
              <DataBox
                title={"Total Orders"}
                value={
                  <NumericLabel params={paramOptions1}>
                    {segmentDetail.orders}
                  </NumericLabel>
                }
              />
            )}
          </Grid>
          <Grid item md>
            <DataBox
              title={"Order(s)/Customer"}
              value={
                <NumericLabel params={paramOptions2}>
                  {segmentDetail.orders / segmentDetail.customers}
                </NumericLabel>
              }
            />
          </Grid>
          <Grid item md>
            <DataBox
              title={"Revenue/Customer"}
              value={
                <NumericLabel params={paramOptions3}>
                  {segmentDetail.revenue / segmentDetail.customers}
                </NumericLabel>
              }
            />
          </Grid>
          {hasLXRTracking && dataSourceLength === 1 ? (
            <Grid item md>
              <DataBox
                title={"Visit(s)/Month"}
                value={
                  <NumericLabel params={paramOptions4}>
                    {segmentDetail.avg_monthly_visits}
                  </NumericLabel>
                }
              />
            </Grid>
          ) : hasLXRTracking && dataSourceLength > 1 ? (
            trackingReady ? (
              <Grid item md>
                <DataBox
                  title={"Visit(s)/Month"}
                  value={
                    <NumericLabel params={paramOptions4}>
                      {segmentDetail.avg_monthly_visits}
                    </NumericLabel>
                  }
                />
              </Grid>
            ) : null
          ) : null}
        </Grid>
      </Box>
    </React.Fragment>
  );
};
const mapStateToProps = (state) => ({
  businessProfile: state.businessProfile,
});

const mapDispatchToProps = (dispatch) => ({
  business_profile: () => dispatch(business_profile()),
});
export default connect(mapStateToProps, mapDispatchToProps)(BlockHeader);
