import React, {useEffect} from "react";
import {AppBar, Box, Toolbar, Typography} from "@mui/material";
import HeaderStyles from "../../assets/jss/headers/headerStyles";
import Icons from "../icons";
import {TransparentButton} from "../buttons";
import {handle_login, login_popup} from "../../actions/actions";
import {connect} from "react-redux";
import LoginForm from "../forms/logInForm";
import {Link} from "react-router-dom";
import clsx from "clsx";
import {GAEventTracker} from "../../index";

function LoggedOutHeader(props) {
    const classes = HeaderStyles();
    const {loginAuthentication,application_states,login_popup,fromShopify} = props;
    const [popLoginOpen, setPopLoginOpen] = React.useState(application_states.loginPopup);
    const handleLoginOpen = async () => {
        await setPopLoginOpen(true);
        await login_popup(true);
    }
    const handleSignup = () => {
        window.location.hash = '/signup'
    }
    const handleLoginClose = async () => {
        await setPopLoginOpen(false)
        await login_popup(false)
    }

    const handle_login = async (e, data) => {
        // e.preventDefault();
        await props.handle_login(e, data);
        if (!loginAuthentication.logged_in) {
            await handleLoginOpen();
        } else {
            await handleLoginClose();
        }
    };
    useEffect(()=>{
        if(loginAuthentication.logged_in) {
            const url = window.location.port ? "http://" + window.location.hostname + ":" + window.location.port + "/#/" : "https://" + window.location.hostname + "/#/";
            window.location.assign(url)
        }
    },[loginAuthentication])
    return (
        <>
            <AppBar className={classes.appBar} position={"fixed"}>
                <Box alignItems={'center'}>
                    <Toolbar disableGutters className={classes.containerSec} id="headSec">
                        <Box className={clsx(classes.flexGrow1, 'flex')} style={{alignItems: 'center'}}>
                            {/*<Box py={'auto'} px={2} borderRight={"1px solid #1e334e"}>*/}
                            <Link to={"/"} className={clsx("flex", "text-decoration-none")}>
                                <Box className={classes.logo}>
                                    {/*{Icons.insightsWhiteLogo}*/}
                                    {Icons.lxrLogo}
                                </Box>
                            </Link>
                            {/*<a target={"_blank"} rel="noopener noreferrer" href={"https://www.netelixir.com/"}
                               className={clsx("flex", "text-decoration-none")}>
                                <Box py={'auto'} px={2} onClick={() => {
                                    GAEventTracker('Header', 'NetElixir Logo')
                                }}>
                                    {Icons.netelixirLogo}
                                </Box>
                            </a>*/}
                        </Box>
                        {fromShopify?null:
                        <Box textAlign={"right"} className={classes.loginButton}>
                            <TransparentButton onClick={() => {
                                handleLoginOpen();
                                GAEventTracker("Header", 'Login')
                            }}>
                                <Typography className={classes.loginButtonTypo} >
                                    Log In
                                </Typography>
                            </TransparentButton>
                        </Box>}
                        {fromShopify?null:
                        <Box textAlign={"right"} className={classes.loginButton} >
                            <TransparentButton onClick={() => {
                                handleSignup();
                                GAEventTracker("Header", 'Signup')
                            }} style={{borderRadius: '30px',background: '#F58120',color: '#fff'}}>
                                <Typography className={classes.loginButtonTypo}>
                                    Sign Up
                                </Typography>
                            </TransparentButton>
                        </Box>}

                    </Toolbar>
                </Box>
            </AppBar>
            <LoginForm handle_login={handle_login} popLoginOpen={popLoginOpen} onClose={handleLoginClose}/>
        </>
    )
}

const mapStateToProps = (state) => ({
    loginAuthentication: state.loginAuthentication,
    application_states:state.application_states
});
const mapDispatchToProps = (dispatch) => ({
    handle_login: (e, data) => dispatch(handle_login(e, data)),
    login_popup: (loginpopup) => dispatch(login_popup(loginpopup)),
})
export default connect(mapStateToProps, mapDispatchToProps)(LoggedOutHeader);
