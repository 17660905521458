import {
  Badge,
  ClickAwayListener,
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import clsx from "clsx";
import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import "../App.css";
import {
  clearExpAudience,
  customerAnalyticsSegmentName,
  exp_audience_fetch,
  exp_campaign_fetch,
  fetch_predefined_exp,
  get_user_data,
} from "../actions/actions";
import HeaderStyles from "../assets/jss/headers/headerStyles";
import Icons from "../components/icons";
import { GAEventTracker } from "../index";
import * as _ from "lodash";

export const drawerWidth = 260;

const CustomListItem = withStyles((theme) => ({
  root: {
    color: theme.palette.common.white,
  },
  gutters: {
    marginLeft: 16,
    paddingLeft: 0,
    "&:hover": {
      backgroundColor: theme.palette.secondary.contrastText,
      width: "89%",
    },
    "&$selected, &$selected:focus, &$selected:hover": {
      backgroundColor: theme.palette.secondary.contrastText,
      borderLeft: "4px Solid #f58120",
      marginLeft: 12,
    },
  },
  selected: {
    focus: {},
    hover: {},
  },
}))(ListItemButton);
const CustomListItemText = withStyles((theme) => ({
  root: {
    paddingLeft: 24,
  },
}))(ListItemText);
const CustomListItemParentText = withStyles((theme) => ({
  root: {
    paddingLeft: 16,
    paddingTop: 8,
  },
}))(ListItemText);
const CustomListItemIcon = withStyles((theme) => ({
  root: {
    paddingLeft: 22,
    paddingRight: 20,
    minWidth: 0,
  },
}))(ListItemIcon);
const CustomBadge = withStyles((theme) => ({
  badge: {
    fontSize: "12px",
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.common.white,
  },
}))(Badge);

function Sidebar(props) {
  const {
    userProfile,
    exp_audience_fetch,
    expAudienceReducer,
    exp_campaign_fetch,
    expCampaignReducer,
    fetch_predefined_exp,
    fetchPredefinedExpReducer,
    clearExpAudience,
  } = props;

  const dispatch = useDispatch();

  let selection = window.location.hash;
  useEffect(() => {}, [selection, userProfile]);

  useEffect(() => {
    if (!userProfile.isLoading) {
      clearExpAudience();
    }
  }, [userProfile.ga_client_id]);

  useEffect(() => {
    if (!userProfile.isLoading && userProfile.email) {
      exp_audience_fetch(userProfile.bu_id);
    }
    if (!userProfile.isLoading && userProfile.email) {
      exp_campaign_fetch();
    }
    if (
      !fetchPredefinedExpReducer.isLoading &&
      !fetchPredefinedExpReducer.data &&
      !userProfile.isLoading &&
      userProfile.email
    ) {
      fetch_predefined_exp();
    }
  }, [userProfile.bu_id]);

  const classes = HeaderStyles();
  const [open, setOpen] = React.useState(false);
  // const [selectedIndex, setSelectedIndex] = React.useState(0);
  // const displayExperiment =  Boolean(userProfile && userProfile.ga_client_id) && Boolean(expAudienceReducer.data && expAudienceReducer.data.some((aud)=> aud.audience_info.search_size >= 1000 || aud.audience_info.display_size >= 1000)) &&  Boolean(expCampaignReducer.data && expCampaignReducer.data.length>0);
  const sidebarTabs = [
    {
      displayCondition: true,
      icon: Icons.overviewIcon,
      iconTitle: "OVERVIEW",
      title: "Dashboard",
      link: "/",
      gaCategory: "Sidebar",
      selectedCondition: "#/",
      badge: false,
    },

    {
      displayCondition: true,
      icon: Icons.allCustomersIcon,
      iconTitle: "ANALYTICS",
      title: "Customer Analytics",
      link: "/customer-analytics/a",
      gaCategory: "Sidebar",
      selectedCondition: "#/customer-analytics/a",
      badge: false,
    },

    {
      displayCondition: true,
      icon: Icons.productAnalyticsIcon,
      iconTitle: null,
      title: "Product Analytics",
      link: "/product_analytics",
      gaCategory: "Sidebar",
      selectedCondition: "#/product_analytics",
      badge: false,
    },

    // {
    //   displayCondition: true,
    //   icon: Icons.allCustomersIcon,
    //   iconTitle: "CUSTOMERS",
    //   title: CustomerGroup("all")().chartName,
    //   link: "/groups/all",
    //   gaCategory: "Sidebar",
    //   selectedCondition: "#/groups/all",
    //   badge:false
    // },
    // {
    //   displayCondition: true,
    //   icon: Icons.newHighCustomersIcon,
    //   iconTitle: null,
    //   title: CustomerGroup("a")().chartName,
    //   link: "/groups/a",
    //   gaCategory: "Sidebar",
    //   selectedCondition: "#/groups/a",
    //   badge:false
    // },
    // {
    //   displayCondition: true,
    //   icon: Icons.newMidCustomersIcon,
    //   iconTitle: null,
    //   title: CustomerGroup("b")().chartName,
    //   link: "/groups/b",
    //   gaCategory: "Sidebar",
    //   selectedCondition: "#/groups/b",
    //   badge:false
    // },
    // {
    //   displayCondition: true,
    //   icon: Icons.newLowCustomersIcon,
    //   iconTitle: null,
    //   title: CustomerGroup("c")().chartName,
    //   link: "/groups/c",
    //   gaCategory: "Sidebar",
    //   selectedCondition: "#/groups/c",
    // },

    {
      // displayCondition: displayExperiment ? true:false,
      displayCondition: true,
      icon: Icons.experimentIcon,
      iconTitle: <>&nbsp;&nbsp;{"LAB"}</>,
      title: "Experiments",
      link: "/experiment-summary",
      gaCategory: "Sidebar",
      selectedCondition: "#/experiment-summary",
      badge: true,
    },
    {
      displayCondition: true,
      icon: Icons.reMarketPageIcon,
      iconTitle: "AUDIENCES",
      title: "Audience Manager",
      link: "/customers/audience",
      gaCategory: "Sidebar",
      selectedCondition: "#/customers/audience",
      badge: false,
    },
    {
      displayCondition: false,
      icon: Icons.audienceAnalyticsPageIcon,
      iconTitle: null,
      title: "Audience Analytics",
      link: "/audienceAnalytics",
      gaCategory: "Sidebar",
      selectedCondition: "#/audienceAnalytics",
      badge: false,
    },
    {
      displayCondition: true,
      icon: Icons.connectionsPageIcon,
      iconTitle: null,
      title: "Connections",
      link: "/connections",
      gaCategory: "Sidebar",
      selectedCondition: "#/connections",
      badge: false,
    },
    {
      displayCondition: true,
      icon: Icons.whiteMagicWand,
      iconTitle: "TRENDS",
      title: "Predictions",
      link: "/predictions",
      gaCategory: "Sidebar",
      selectedCondition: "#/predictions",
      badge: false,
    },
    {
      displayCondition: true,
      icon: Icons.customerTrendIcon,
      iconTitle: null,
      title: "Customer Trend",
      link: "/customer-trend",
      gaCategory: "Sidebar",
      selectedCondition: "#/customer-trend",
      badge: false,
    },
  ];

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  return (
    <ClickAwayListener onClickAway={handleDrawerClose}>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
        onMouseOver={handleDrawerOpen}
        onMouseOut={handleDrawerClose}
      >
        <Toolbar />
        <List style={{ marginTop: "20px" }}>
          {sidebarTabs.map((tab, index) =>
            tab.displayCondition ? (
              <React.Fragment key={index}>
                {tab.iconTitle !== null ? (
                  <CustomListItemParentText
                    primary={
                      <Typography
                        style={{ fontSize: 10, fontWeight: 500, opacity: 0.5 }}
                      >
                        {tab.iconTitle}
                      </Typography>
                    }
                  />
                ) : null}
                <Link
                  to={tab.link}
                  id={index === 0 ? "tour1stStep" : null}
                  className={clsx("flex", "text-decoration-none")}
                >
                  <CustomListItem
                    selected={selection === tab.selectedCondition}
                    onClick={() => {
                      // setSelectedIndex(index);

                      if (tab?.link === "/customer-analytics/a") {
                        dispatch(
                          customerAnalyticsSegmentName({
                            customerAnalyticsSegmentTabName: "a",
                          })
                        );
                      }

                      handleDrawerClose();
                      GAEventTracker(tab.gaCategory, tab.title);
                    }}
                    className={open ? classes.afterHover : classes.beforeHover}
                  >
                    <CustomBadge badgeContent={tab.badge ? "New" : null}>
                      <CustomListItemIcon className={classes.menuItemIcon}>
                        {tab.icon}
                      </CustomListItemIcon>
                    </CustomBadge>
                    <CustomListItemText
                      primary={
                        <Typography style={{ fontSize: 14, fontWeight: 500 }}>
                          {tab.title}
                        </Typography>
                      }
                    />
                  </CustomListItem>
                </Link>
              </React.Fragment>
            ) : null
          )}
        </List>
      </Drawer>
    </ClickAwayListener>
  );
}

const mapStateToProps = (state) => ({
  expAudienceReducer: state.expAudienceReducer,
  expCampaignReducer: state.expCampaignReducer,
  fetchPredefinedExpReducer: state.fetchPredefinedExpReducer,
  businessProfile: state.businessProfile,
});

const mapDispatchToProps = (dispatch) => ({
  exp_audience_fetch: (property_id) =>
    dispatch(exp_audience_fetch(property_id)),
  exp_campaign_fetch: () => dispatch(exp_campaign_fetch()),
  fetch_predefined_exp: () => dispatch(fetch_predefined_exp()),
  get_user_data: () => dispatch(get_user_data()),
  clearExpAudience: () => dispatch(clearExpAudience()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
