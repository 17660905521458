import { Box, Grid, Tooltip, Typography } from "@mui/material";
import { useTheme, withStyles } from "@mui/styles";
import clsx from "clsx";
import moment from "moment";
import React, { useEffect, useState } from "react";
import NumericLabel from "react-pretty-numbers";
import { connect, useSelector } from "react-redux";
import { ReportDownloaded, business_profile } from "../../actions/actions";
import { border } from "../../assets/jss/commonStyles";
import GroupPageStyles from "../../assets/jss/groupPageStyles";
import { GAEventTracker } from "../../index";
import { SimpleButton } from "../buttons";
import CustomerGroup from "../getGroupDetails";
import Icons from "../icons";

function DataBox(props) {
  const { title, value, percent, comparedPeriod, selectedPeriod } = props;
  const theme = useTheme();
  return (
    <Box
      border={border}
      borderRadius="6px"
      boxShadow={"4px 4px 18px rgba(0, 0, 0, 0.06)"}
      p={2}
      pb={1}
      style={{ textAlign: "center" }}
    >
      <Typography
        variant={"h2"}
        color={"primary"}
        style={{ fontWeight: 700 }}
        component={"span"}
      >
        {value}
      </Typography>
      &nbsp;
      <Typography variant={"body1"} component={"span"}>
        {percent && percent !== "-" ? (
          percent > 0 ? (
            <CustomTooltip
              style={{ backgroundColor: theme.palette.primary.main }}
              title={
                <>
                  <Typography variant="h5" style={{ marginBottom: "6px" }}>
                    Current Period:
                    <br />
                    {selectedPeriod}
                    <br />
                  </Typography>
                  <Typography variant="h5">
                    Compared Period:
                    <br />
                    {comparedPeriod}
                  </Typography>
                </>
              }
              placement="top"
              arrow
            >
              <span style={{ color: "#27AE60" }}>
                &nbsp;
                <NumericLabel>{Math.abs(percent)}</NumericLabel>% &nbsp;
                {Icons.greenLineArrow}
              </span>
            </CustomTooltip>
          ) : (
            <CustomTooltip
              title={
                <>
                  <Typography variant="h5" style={{ marginBottom: "6px" }}>
                    Current Period:
                    <br />
                    {selectedPeriod}
                    <br />
                  </Typography>
                  <Typography variant="h5">
                    Compared Period:
                    <br />
                    {comparedPeriod}
                  </Typography>
                </>
              }
              placement="top"
              arrow
            >
              <span style={{ color: "#eb5757" }}>
                &nbsp;
                <NumericLabel>{Math.abs(percent)}</NumericLabel>% &nbsp;
                {Icons.redLineArrow}
              </span>
            </CustomTooltip>
          )
        ) : percent === "-" ? (
          <span
            style={{
              color: theme.palette.primary.main,
              opacity: 0.5,
            }}
          >
            &ensp;
          </span>
        ) : (
          <CustomTooltip
            title={
              <>
                <Typography variant="h5" style={{ marginBottom: "6px" }}>
                  Current Period:
                  <br />
                  {selectedPeriod}
                  <br />
                </Typography>
                <Typography variant="h5">
                  Compared Period:
                  <br />
                  {comparedPeriod}
                </Typography>
              </>
            }
            placement="top"
            arrow
          >
            <span
              style={{
                color: theme.palette.primary.main,
                opacity: 0.5,
              }}
            >
              &ensp;0%
            </span>
          </CustomTooltip>
        )}
      </Typography>
      <Typography variant={"body1"} color={"primary"} gutterBottom={true}>
        {title}
      </Typography>
    </Box>
  );
}

export const CustomTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontSize: 11,
  },
  arrow: {
    color: theme.palette.primary.main,
  },
}))(Tooltip);

function GroupOverview(props) {
  const classes = GroupPageStyles();
  const {
    data,
    ReportDownloaded,
    setRemarketDialogOpen,
    // currency, country_code,setRemarketDialogOpen,// businessProfile,
    business_profile,
    // currencySymbol,
    hasLXRTracking,
    trackingReady,
    dataSourceLength,
  } = props;
  const countryCode =
    useSelector((state) => state?.userProfile?.country_codes) || "";
  const currency = useSelector((state) => state?.application_states?.currency);

  const currencySymbol = useSelector(
    (state) => state?.application_states?.currencySymbol
  );

  const overViewReducerDataList = useSelector(
    (state) => state?.overview_reducer?.data
  );

  const [datePeriodState, setDatePeriodState] = useState({
    data_start: new Date(),
    data_end: new Date(),
  });

  const businessProfile = useSelector((state) => state?.businessProfile);
  const App_URL = process.env.REACT_APP_AppUrl;
  const paramOptions5 = {
    commafy: true,
    wholenumber: "ceil",
    shortFormatMinValue: 10000,
    justification: "L",
  };
  const paramOptions3 = {
    wholenumber: "ceil",
    locales: "en-US",
    currency: true,
    shortFormatMinValue: 10000,
    currencyIndicator: currency,
    commafy: true,
    justification: "L",
  };
  const paramOptions1 = {
    wholenumber: "ceil",
    locales: "en-US",
    currency: true,
    shortFormatMinValue: 10000,
    currencyIndicator: currency,
    commafy: true,
    justification: "L",
    shortFormat: true,
    precision: 1,
  };
  const paramOptions4 = {
    wholenumber: "ceil",
    justification: "L",
  };
  // useEffect(()=>{
  //   business_profile()
  //},[])

  const selectedPeriod = `${moment(data.data_start).format(
    "MMM YYYY"
  )} to ${moment(data.data_end).format("MMM YYYY")}`;
  const comparedPeriod = `${moment(data.data_start)
    .subtract(1, "months")
    .format("MMM YYYY")} to ${moment(data.data_end)
    .subtract(1, "months")
    .format("MMM YYYY")}`;

  useEffect(() => {
    if (overViewReducerDataList?.length) {
      const segmentAllArr = overViewReducerDataList?.filter(
        (each) => each?.segment === "all"
      );

      setDatePeriodState({
        data_start: segmentAllArr?.[0]?.data_start,
        data_end: segmentAllArr?.[0]?.data_end,
      });
    }
  }, [overViewReducerDataList]);

  return (
    <React.Fragment>
      <Box className={clsx("flex", classes.headerStyles)} borderBottom={border}>
        <Grid container alignItems={"center"}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Grid
                container
                spacing={1}
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>{Icons?.keyMetricsIcon}</Grid>
                <Grid item>
                  <Typography
                    variant={"body1"}
                    color={"primary"}
                    style={{
                      fontWeight: 600,
                      fontFamily: "Lato, sans-serif",
                    }}
                  >
                    Key Metrics :
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    variant={"h5"}
                    color={"primary"}
                    style={{ fontWeight: 400 }}
                  >
                    {`(${moment(datePeriodState?.data_start).format(
                      "MMMM YYYY"
                    )} to ${moment(datePeriodState?.data_end).format(
                      "MMMM YYYY"
                    )})`}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item>
              <Grid
                container
                spacing={1}
                justifyContent="center"
                alignItems="center"
              >
                <Grid item>
                  <CustomTooltip title={"Download"} arrow interactive="true">
                    <a
                      href={
                        App_URL !== ""
                          ? `${App_URL}/download/order_report/${data?.report_name}`
                          : `/download/order_report/${data?.report_name}`
                      }
                      download={"order_report"}
                      style={{ textDecoration: "none" }}
                    >
                      <SimpleButton
                        id={data.segment === "a" ? "tour7thStep" : null}
                        onClick={() => {
                          GAEventTracker(
                            CustomerGroup(data.segment)().name,
                            "Download",
                            "Customers List"
                          );
                          ReportDownloaded(
                            data.report_name,
                            "Group Customers List",
                            data.segment
                          );
                        }}
                        style={{ margin: 0 }}
                      >
                        {Icons?.downloadOrangeIconImg}
                      </SimpleButton>
                    </a>
                  </CustomTooltip>
                </Grid>
                <Grid
                  item
                  borderRight="2px solid #E8EBF6"
                  height={"1.5rem"}
                  marginTop={1}
                />

                <Grid item>
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setRemarketDialogOpen(true);
                      GAEventTracker(
                        "Customer Reports",
                        "Remarket",
                        "Customer Report"
                      );
                    }}
                  >
                    {Icons?.remarketOrangeIconImg}
                    <Typography
                      variant="body1"
                      color="#F58120"
                      style={{ fontWeight: 600, marginLeft: 8 }}
                    >
                      Re-Market
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={1} lg={1} />
          <Grid item xs={12} sm={9} lg={4}></Grid>
        </Grid>
      </Box>
      <Box px={1} py={2}>
        <Grid container alignItems={"center"} spacing={2}>
          <Grid item md>
            <DataBox
              title={
                countryCode?.length === 1
                  ? // country_code
                    `Total Customers (${countryCode?.[0]})`
                  : "Total Customers"
              }
              value={
                <NumericLabel params={paramOptions5}>
                  {data.customers}
                </NumericLabel>
              }
              percent={
                data["change%_in_customers"] !== null
                  ? Math.round(data["change%_in_customers"])
                  : "-"
              }
              selectedPeriod={selectedPeriod}
              comparedPeriod={comparedPeriod}
            />
          </Grid>
          <Grid item md>
            <DataBox
              title={"Total Orders"}
              value={
                <NumericLabel params={paramOptions5}>
                  {data.orders}
                </NumericLabel>
              }
              percent={
                data["change%_in_orders"] !== null
                  ? Math.round(data["change%_in_orders"])
                  : "-"
              }
              selectedPeriod={selectedPeriod}
              comparedPeriod={comparedPeriod}
            />
          </Grid>
          <Grid item md>
            <DataBox
              title={"Total Revenue"}
              value={
                <NumericLabel
                  params={
                    data.revenue > 1000000 ? paramOptions1 : paramOptions3
                  }
                >
                  {data.revenue}
                </NumericLabel>
              }
              percent={
                data["change%_in_revenue"] !== null
                  ? Math.round(data["change%_in_revenue"])
                  : "-"
              }
              selectedPeriod={selectedPeriod}
              comparedPeriod={comparedPeriod}
            />
          </Grid>
          <Grid item md>
            <DataBox
              title={"Revenue/Customer"}
              value={
                <NumericLabel params={paramOptions3}>
                  {data.revenue / data.customers}
                </NumericLabel>
              }
              percent={
                data["change%_in_revenue_per_customers"] !== null
                  ? Math.round(data["change%_in_revenue_per_customers"])
                  : "-"
              }
              selectedPeriod={selectedPeriod}
              comparedPeriod={comparedPeriod}
            />
          </Grid>
          {hasLXRTracking && dataSourceLength === 1 ? (
            <Grid item md>
              <DataBox
                title={"Visit(s)/Month"}
                value={
                  <NumericLabel params={paramOptions4}>
                    {data.avg_monthly_visits}
                  </NumericLabel>
                }
                percent={
                  data["change%_in_avg_monthly_visits"] !== null
                    ? Math.round(data["change%_in_avg_monthly_visits"])
                    : "-"
                }
                selectedPeriod={selectedPeriod}
                comparedPeriod={comparedPeriod}
              />
            </Grid>
          ) : hasLXRTracking && dataSourceLength > 1 ? (
            trackingReady ? (
              <Grid item md>
                <DataBox
                  title={"Visit(s)/Month"}
                  value={
                    <NumericLabel params={paramOptions4}>
                      {data.avg_monthly_visits}
                    </NumericLabel>
                  }
                  percent={
                    data["change%_in_avg_monthly_visits"] !== null
                      ? Math.round(data["change%_in_avg_monthly_visits"])
                      : "-"
                  }
                  selectedPeriod={selectedPeriod}
                  comparedPeriod={comparedPeriod}
                />
              </Grid>
            ) : null
          ) : null}
        </Grid>
      </Box>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  businessProfile: state.businessProfile,
});

const mapDispatchToProps = (dispatch) => ({
  ReportDownloaded: (report_filename, report_type, segment) =>
    dispatch(ReportDownloaded(report_filename, report_type, segment)),
  business_profile: () => dispatch(business_profile()),
});
export default connect(mapStateToProps, mapDispatchToProps)(GroupOverview);
