import React, {useEffect} from 'react'
import OverviewHeader from "../components/overviewPage/overviewHeader"
import OverviewBlock from "../components/overviewPage/overviewBlock"
import OverviewTable from "../components/overviewPage/overviewTable"
import OverviewPageStyles from "../assets/jss/overviewPageStyles"
import {Box, CircularProgress, Grid, useTheme} from "@mui/material";
import {connect} from "react-redux";
import {
  get_user_data,
  overview_compare,
  overview_data,
  PopulationData,
  product_tour_run,
  SegmentOrderData,
  SegmentPathData,
  SegmentProductData,
  set_window_height,
  UserSegmentData
} from "../actions/actions";
import Header from "../components/headers/header";
import Footer from "../components/footer";
import ReactGA from "react-ga4";
import ProductTourDialog from "../components/productTour/productTourDialog";
import {tour} from "../components/productTour/productTour";
import ChangePassword from "../components/SettingsPage/changePassword";
import moment from "moment"
import DowntimeBanner from '../components/downtimeBanner'
import FbReauthenticatePopup from '../components/fbReauthenticatePopup'
import GoogleReauthenticatePopup from '../components/GoogleReauthenticatePopup'

function OverviewPage(props) {
    const classes = OverviewPageStyles();
    const theme = useTheme();
    const {
      overview_data,
      overview_reducer,
      application_states,
      overview_compare,
      UserSegmentData,
      user_segment_reducer,
      userProfile,
      productTourReducer,
      get_user_data,
      businessProfile,
        set_window_height
    //   product_tour_run,
    } = props;
    const [dialogOpen, setDialogOpen] = React.useState(moment(new Date()).diff(new Date(userProfile.password_updated), "months") >=11);
    const currency = application_states.currency;
    const handleCompare = async (value) => {
        await overview_compare(value)
    };
    const compare = application_states.overviewCompare;
    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.hash});
        window.scrollTo(0, 0);
        window.addEventListener("resize", set_window_height);
        async function fetchData() {
            if (!overview_reducer.data) {
                await overview_data()
            }
        }

        fetchData();
    //eslint-disable-next-line
    }, []);
    useEffect(() => {
        async function fetchUsers() {
            if (user_segment_reducer.isLoading === false && !user_segment_reducer.users) {
                await UserSegmentData()
            }
        }

        fetchUsers();
    //eslint-disable-next-line
    }, [overview_reducer]);
    useEffect(() => {
        async function startTourManual() {
            if(productTourReducer.run && (window.hopscotch.getCurrStepNum()===undefined || window.hopscotch.getCurrStepNum()===0)) {
                await window.hopscotch.endTour(tour);
                await window.hopscotch.startTour(tour)
            }
        }

        startTourManual();
    }, [productTourReducer]);
    useEffect(()=>{
        if (window.hopscotch && window.hopscotch != null && productTourReducer.run) {
            const currentStep = window.hopscotch.getCurrStepNum();
            if (currentStep) {
                const currentTour = window.hopscotch.getCurrTour();
                window.hopscotch.startTour(currentTour, currentStep);
            }
        }
        if(!productTourReducer.run ){
            get_user_data()
        }
        //eslint-disable-next-line
    },[productTourReducer]);
    let country_code = (userProfile.country_codes && (userProfile.country_codes.length === 1)) ? userProfile.country_codes[0] : '';
    const [fbopen, setFbopen] = React.useState(localStorage.getItem("fbopen"));    
    useEffect(()=>{},[fbopen]);
    return (
        <div>
            <Header/>
            <div className={'flex'}>
                <Grid container>
                    <Grid item md={12}>
                        {overview_reducer.isLoading ?
                            <div style={{height: window.innerHeight}}>
                                <CircularProgress size={100} style={{
                                    position: 'absolute',
                                    left: '50%',
                                    bottom: '50%',
                                    color: theme.palette.secondary.light
                                }}/>
                            </div> :
                            <div className={classes.content}>
                                <div className={classes.toolbar}/>
                                <div style={{minHeight:application_states.window_height-120}}>
                                <DowntimeBanner/>
                                <OverviewHeader data={overview_reducer.data} classes={classes} compare={compare}
                                                handleCompare={handleCompare}/>
                                <Box p={1} pr={1}>
                                    {compare ?
                                        <OverviewTable country_code={country_code} classes={classes}
                                                       overview_reducer={overview_reducer} max_window_height={application_states.window_height}
                                                       currency={currency} tracking={businessProfile.ecom}/>
                                        :
                                        <OverviewBlock country_code={country_code} overview_reducer={overview_reducer}
                                                       currency={currency}
                                                       user_segment_reducer={user_segment_reducer}/>}
                                </Box>
                                </div>
                                <Footer/>
                                {userProfile.terms_accepted ? <ProductTourDialog/>:null}
                                {userProfile.terms_accepted && userProfile.product_tour !== 0 ? <ChangePassword open={(dialogOpen && application_states.showPasswordPopup)} setDialogOpen={setDialogOpen}/>:null}
                                {userProfile.terms_accepted && userProfile.product_tour !== 0 && dialogOpen === false? userProfile.ga_client_id ?<GoogleReauthenticatePopup setFbopen={setFbopen}/>:null:null}
                                {userProfile.terms_accepted && userProfile.product_tour !== 0 && dialogOpen === false && !userProfile.ga_token_expiry_flag | fbopen === "true"? userProfile.fb_account_id ?<FbReauthenticatePopup/>:null:null}
                            </div>
                        }
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
  overview_reducer: state.overview_reducer,
  application_states: state.application_states,
  segment_order_reducer: state.segment_order_reducer,
  segment_path_reducer: state.segment_path_reducer,
  segment_product_reducer: state.segment_product_reducer,
  population_data_reducer: state.population_data_reducer,
  user_segment_reducer: state.user_segment_reducer,
  userProfile: state.userProfile,
  productTourReducer: state.productTourReducer,
  businessProfile: state.businessProfile
});

const mapDispatchToProps = (dispatch) => ({
    overview_data: () => dispatch(overview_data()),
    PopulationData: () => dispatch(PopulationData()),
    UserSegmentData: () => dispatch(UserSegmentData()),
    overview_compare: (value) => dispatch(overview_compare(value)),
    SegmentOrderData: (preFetch, segment, start, end) => dispatch(SegmentOrderData(preFetch, segment, start, end)),
    SegmentPathData: (preFetch, segment, top, start, end) => dispatch(SegmentPathData(preFetch, segment, top, start, end)),
    SegmentProductData: (preFetch, segment, top, start, end) => dispatch(SegmentProductData(preFetch, segment, top, start, end)),
    product_tour_run: (tourRunValue) => dispatch(product_tour_run(tourRunValue)),
    get_user_data: () => dispatch(get_user_data()),
    set_window_height: () => dispatch(set_window_height())

});
export default connect(mapStateToProps, mapDispatchToProps)(OverviewPage);