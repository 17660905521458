import makeStyles from '@mui/styles/makeStyles';
const App_URL = process.env.REACT_APP_AppUrl;


const HomePageStyles = makeStyles((theme) => ({
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    // padding: theme.spacing(1),
    // paddingTop: theme.spacing(2),
    // backgroundColor: theme.palette.common.white,
  },
  /*videoContainer: {
        position: "fixed",
        top: 0,
        bottom: 0,
        width: "100%",
        height: "100%",
        overflow: "hidden",
    },*/
  vid_container: {
    paddingTop: 48,
    [theme.breakpoints.down(960)]: {
      paddingTop: 0,
    },
    overflow: "hidden",
    position: "relative",
    float: "left",
  },
  /*myVideo: {
        maxWidth: "100%",
        minHeight: "100%",
        width: "auto",
        height: "auto",
        position: "fixed",
        top: 0,
        left: 0,
        transform: "translate(0,0)",
    },*/
  homeVideo: {
    position: "relative",
    top: "0",
    maxWidth: "100%",
    zIndex: "-100",
    overflow: "hidden",
    [theme.breakpoints.up(1280)]: {
      transform: "translate(0%, -10%)",
    },
    float: "left",
  },
  matter: {
    position: "absolute",
    top: "10rem",
    [theme.breakpoints.down(500)]: {
      top: "13%",
      left: "17%",
      padding: 10,
    },
    [theme.breakpoints.only(960)]: {
      top: "18%",
      left: "17%",
      padding: 10,
    },
    [theme.breakpoints.up(1920)]: {
      width: "50%",
    },
    left: "21%",
    color: theme.palette.common.white,
    padding: 20,
    float: "left",
  },
  superS: {
    fontSize: "2vw",
    fontWeight: "normal",
    // top: "-1.5em"
    position: "absolute",
  },
  ctabg: {
    backgroundColor: theme.palette.white.mainBg,
  },
  proximaNovaFont: {
    fontFamily: '"proxima-nova", sans-serif',
  },
  highValue: {
    display: "block",
    fontWeight: "600",
    marginTop: "30",
    fontSize: "24",
    textDecoration: "underline",
    lineHeight: "initial",
  },
  facesLink: {
    fontFamily: '"Proxima Nova", Sans-serif',
    fontSize: "20px",
    fontWeight: "700",
    fill: "#000000",
    color: "#000000",
    backgroundColor: "#61CE7000",
    borderStyle: "solid",
    borderWidth: "0px 0px 2px 0px",
    borderRadius: "0px 0px 0px 0px",
    padding: "0px 20px 5px 0px",
    [theme.breakpoints.down(767)]: {
      fontSize: "18px !important",
    },
  },
  brandTrust: {
    fontSize: "22px",
    fontWeight: "700",
    lineHeight: "1.4em",
  },
  headerSection: {
    backgroundSize: "cover",
    backgroundPosition: "bottom",
    [theme.breakpoints.down(767)]: {
      backgroundPosition: "50%",
      paddingBottom: "12%",
    },
  },
  headerContent: {
    position: "absolute",
    [theme.breakpoints.down(430)]: {
      top: "10%",
      '@media (orientation: landscape)': {
        top:'19%',
      },
    },
    // [theme.breakpoints.between(431,500)]: {
    //   top: "9%",
    // },
    [theme.breakpoints.between(500,800)]: {
      top: "10%",
      '@media (orientation: landscape)': {
        top:'19%',
      },
    },
    [theme.breakpoints.between(800,1000)]: {
      top: "10%",
      '@media (orientation: landscape)': {
        top:'19%',
      },
    },
    [theme.breakpoints.between(1001,1366)]: {
      top: "10%",
      '@media (orientation: landscape)': {
        top:'13%',
      },
    },
    [theme.breakpoints.up(1366)]: {
      top: "9%",
      // '@media (orientation: landscape)': {
      //   top:'13%',
      // },
    },
    top: "12%",

  },
  moreRevenue: {
    fontSize: "6vw",
    lineHeight: "12vh",
    color: "#fff",
  },
  logoSlider: {
    maxWidth: "800px",
    margin: "0 auto",
    padding: "20px",
  },
  logoSliderimg: {
    maxHeight: "100px", /* Adjust as needed */
    margin: "0 auto",
    display: "block",
  },
  demofooter: {
    background:
      "transparent linear-gradient(90deg, #F58120 0%, #D6B300 100%) 0% 0% no-repeat padding-box",
    borderRadius: "30px",
    textTransform: "inherit",
  },
  logo: {
    padding: "0px 20px",
  },
  // formWork: {
  //   padding: "50px",
  //   height: "600px",
  //   background:
  //     "transparent radial-gradient(closest-side at 51% 0%, #004F84 0%, #081E30 100%) 0% 0% no-repeat padding-box",
  //   border: "1px solid #707070",
  // },
  pltWork: {
    color: "#fff",
    paddingTop: "16px",
    fontSize: "3rem",
    [theme.breakpoints.down(960)]: {
      fontSize: "2rem",
    },
    textAlign: "center",
    fontFamily: '"proxima-nova", sans-serif',
    fontWeight: "700",
    marginTop: "3%",
  },
  ctaDemo: {
    color: theme.palette.secondary.light,
    fontSize: "2.3rem",
    lineHeight: 1.2,
    fontFamily: '"proxima-nova", sans-serif',
  },
  firstparty: {
    padding: "0px 0px 0px 0px",
    textAlign: "left",
    fontSize: "0.85rem",
    [theme.breakpoints.down(960)]: {
      fontSize: "14px",
      padding: "0px 0px 0px 12px",
    },
    fontFamily: '"proxima-nova", sans-serif',
    letterSpacing: "0px",
    color: "#FFFFFF",
  },
  bannerTitle: {
    fontSize: "6vw !important",
    lineHeight: "12vh",
    [theme.breakpoints.down(960)]: {
      lineHeight: "10vh",
    },
  },
  boxSec: {
    height: "auto",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    borderRadius: "26px",
  },
  MuiTabWrapper: {
    fontSize: "1vw",
  },
  containerSec: {
    maxWidth: "1200px",
    margin: "auto",
    [theme.breakpoints.down(767)]: {
      paddingLeft: "10px !important",
      paddingRight: "10px !important",
    },
  },
  formWork: {
    maxWidth: "1200px",
    margin: "auto",
  },
  requestButton: {
    background:
      "transparent linear-gradient(90deg, #F58120 0%, #D6B300 100%) 0% 0% no-repeat padding-box",
    fontSize: "1rem",
    fontFamily: '"proxima-nova", sans-serif',
    padding: "18px !important",
  },
  statementLine: {
    fontSize: "32px",
    height: "160px",
    fontWeight: "600",
  },
  latestFeatures: {
    color: "#072B51",
    fontFamily: '"proxima-nova", sans-serif',
    fontSize: "2.5rem",
    fontWeight: 700,
    textAlign: "center",
    [theme.breakpoints.down(960)]: {
      fontSize: "2.2rem",
      width: "85%",
      margin: "auto",
      paddingBottom: "20px",
      lineHeight: "2.2rem",
    },
  },
  benefits: {
    color: "#000",
    fontFamily: '"proxima-nova", sans-serif',
    fontSize: "3rem",
    fontWeight: 700,
    textAlign: "left",
    lineHeight: "0.9em",
    marginTop: "3%",
    [theme.breakpoints.down(1198)]: {
      textAlign: "center",
    },
  },
  marketPlatform: {
    color: "#000",
    fontFamily: '"proxima-nova", sans-serif',
    fontSize: "1.625rem",
    fontWeight: 700,
    textAlign: "left",
    lineHeight: "0.9em",
    [theme.breakpoints.down(767)]: {
      fontSize: "2rem",
    },
  },
  maximize: {
    color: "#000",
    fontFamily: '"proxima-nova", sans-serif',
    fontSize: "1.125rem",
    lineHeight: "1.4em",
    [theme.breakpoints.down(960)]: {
      padding: "0 15px",
    },
  },
  keyBenefits: {
    color: "#000",
    fontFamily: '"proxima-nova", sans-serif',
    fontSize: "1.125rem",
    fontWeight: "300",
    lineHeight: "1.4em",
    marginTop: "12%",
    [theme.breakpoints.down(1198)]: {
      textAlign: "center",
      padding: "0 20px",
      color: "#000",
      marginTop: "8%",
    },
  },
  benefitsList: {
    borderStyle: "solid",
    borderWidth: "0px 0px 0px 1px",
    borderColor: "#000000",
    transition: "background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s",
    padding: "0px 0px 0px 0px",
    height: "390px",
    [theme.breakpoints.down(1198)]: {
      height: "auto",
      textAlign: "left",
      borderWidth: "0px 0px 0px 0px",
    },
  },
  latestFeaturesValue: {
    color: theme.palette.common.black,
    fontSize: "2rem",
    fontWeight: 700,
    [theme.breakpoints.down(960)]: {
      fontSize: "1.5rem",
      textAlign: "center",
    },
  },
  marketingPlatform: {
    lineHeight: "1.5",
    color: "#000",
    fontSize: "18px",
    maxWidth: "650px",
    margin: "0 auto",
    fontFamily: '"proxima-nova", sans-serif',
    textAlign: "left",
    paddingTop: "1%",  
    [theme.breakpoints.down(960)]: {
      paddingTop: "5%", 
    },
  },
  impact: {
    color: "#000",
    fontFamily: '"proxima-nova", sans-serif',
    fontSize: "3rem",
    fontWeight: 700,
    lineHeight: "0.9em",
    [theme.breakpoints.down(767)]: {
      paddingBottom: "5%",
      fontSize: "2.5rem",
    },
  },
  strategy: {
    color: "#000",
    fontFamily: '"proxima-nova", sans-serif',
    fontSize: "1.125rem",
    fontWeight: "100",
    lineHeight: "1.4em",
    maxWidth: "900px",
    margin: "2% auto 0",
  },
  platformWork: {
    color: "#000",
    fontFamily: '"proxima-nova", sans-serif',
    fontSize: "1.625rem",
    fontWeight: 700,
    lineHeight: "0.9em",
    padding: "2% 0 0",
    [theme.breakpoints.down(960)]: {
      fontSize: "2rem",
    },
  },
  highValueCust: {
    color: "#000",
    fontFamily: '"proxima-nova", sans-serif',
    fontSize: "3rem",
    fontWeight: 700,
    lineHeight: "0.9em",
    paddingBottom: "15px",
    [theme.breakpoints.down(960)]: {
      padding: "5%",
      textAlign: "center",
      lineHeight: "1.2em",
    },
  },
  sameSpend: {
    color: "#000000",
    fontFamily: '"Proxima Nova", Sans-serif',
    fontSize: "18px",
    fontWeight: "300",
    lineHeight: "1.4em",
  },
  mediaRevenue: {
    color: "#fff",
    fontFamily: '"Proxima Nova", Sans-serif',
    fontWeight: "300",
    lineHeight: "1.2em",
    [theme.breakpoints.up(768)]: {
      fontSize: "20px",
    },
    textAlign: "center",
    [theme.breakpoints.down(767)]: {
      fontSize: "18px !important",
      padding: "5% 0",
    },
  },
  sameRevenue: {
    color: "#FFFFFF",
    fontFamily: '"Proxima Nova", Sans-serif',
    fontSize: "70px",
    fontWeight: 700,
    lineHeight: "0.8em",
    textAlign: "center",
    padding: "2% 0 2%",
    [theme.breakpoints.down(960)]: {
      // padding: "5% 0 2%",
      fontSize: "40px",
    },
  },
  revenueDot: {
    color: "#FFFFFF",
    fontSize: "80px",
    fontWeight: 700,
    fontFamily: "auto",
  },
  contentDot: {
    fontFamily: "auto",
  },
  brands: {
    color: "#fff",
    fontFamily: '"Proxima Nova", Sans-serif',
    fontSize: "20px",
    fontWeight: "700",
    lineHeight: "1.4em",
    textAlign: "center",
  },
  latestFeaturesContent: {
    color: "#555555",
    fontSize: "1.5rem",
    [theme.breakpoints.down(960)]: {
      fontSize: "0.875rem",
    },
  },
  arrowDown: {
    borderLeft: "50px solid transparent",
    borderRight: "50px solid transparent",
    borderTop: "50px solid #fff",
    position: "absolute",
    left: "47%",
  },
  latestFeaturesContainer: {
    maxWidth: "1240px",
    margin: "auto",
    [theme.breakpoints.down(960)]: {
      // marginLeft: 0,
    },
  },
  latestFeaturesImage: {
    width: "80%",
    display: "block",
    [theme.breakpoints.down(960)]: {
      width: "100%",
      display: "block",
    },
  },
  footerDesktop:{
      borderRadius: '20px 20px 20px 20px',
      height: '250px',
      background:"linear-gradient(93deg, #F4F0FF 0.17%, #F4F0FF 0.17%, #F5F1FF 0.18%, #F6F2FF 99.82%)",
      // backgroundImage: `url(${App_URL}${footerBackground})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.15)",
  },
  footerMobile:{
    borderRadius: '20px 20px 20px 20px',
    height: '200px',
    background:"linear-gradient(93deg, #F4F0FF 0.17%, #F4F0FF 0.17%, #F5F1FF 0.18%, #F6F2FF 99.82%)",
    // backgroundImage: `url(${App_URL}${footerBackground})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.15)",
},
hoverLink:{
  color: "#BFBFBF",
  fontSize: "1.125rem",
  fontFamily: '"proxima-nova", sans-serif',
  textDecoration:'none',
  '&:hover': {
    color:theme.palette.secondary.light
  },
}
}));

export default HomePageStyles;
