import React, {useEffect} from "react";
import {connect} from "react-redux";
import LoggedInHeader from "./loggedInHeader"
import LoggedOutHeader from "./loggedOutHeader"

function Header(props) {
    const {loginAuthentication,fromShopify} = props;
    useEffect(()=>{
      //eslint-disable-next-line
    },[localStorage])
    return (
        loginAuthentication.logged_in || localStorage.getItem('access') ?
            <LoggedInHeader/>
            :
            <LoggedOutHeader fromShopify={fromShopify}/>
    )
}

const mapStateToProps = (state) => ({
    loginAuthentication: state.loginAuthentication
});
export default connect(mapStateToProps, null)(Header);